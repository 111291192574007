import React from "react";
import { useTranslation } from "react-i18next";
import Heading from "../Reusable/Heading";

//images import
import InclusiveSexEd from "../../Assets/Images/InclusiveSexEd.jpg";
import Products from "../../Assets/Images/Products.jpg";
import Podcast from "../../Assets/Images/Podcast.jpg";
import Consultancy from "../../Assets/Images/Consultancy.jpeg";
import { RiExternalLinkLine } from "react-icons/ri";
import AnalyticsService from "../../Services/Analytics";
import ChatbotLogo from "../../Assets/Chatbot/BotLogo_withbg.png";
// import { useHistory } from "react-router-dom";

import "./style.css";

export default () => {
  const { t } = useTranslation();

  // const history = useHistory();

  let count = "02";
  let heading = t("about_us_services_title");
  let title = t("about_us_services_body");

  return (
    <React.Fragment>
      <div className="flx">
        <Heading
          count={count}
          heading={heading}
          title={title}
          titleColor="#616161"
          headingColor="#313131"
        />
      </div>
      <div id="services-container" className="services-wrapper flx">
        <div>
          <img src={InclusiveSexEd} alt="inclusive sex ed" />
          <div
            className="services-card odd-card"
            id="services-card-1"
            data-aos="fade-right"
            data-aos-anchor="#services-container"
          >
            <div>
              <h1>1</h1>
            </div>
            <div>
              <h2>
                <a
                  // href="/Videos"
                  href="/"
                  onClick={async (e) => {
                    e.preventDefault();
                    await AnalyticsService.gaEvent(
                      "Home-service",
                      `Link Redirected`,
                      // `Videos Link`,
                      `Chatbot Link`,
                      1
                    );
                    // history.push("/Videos");
                    // window.open("/Chatbot?enterprise=SWS", "_self");
                    window.open("/Chatbot", "_self");
                  }}
                  className="not-anchor onhover-red"
                >
                  {t("about_us_digital_title")} <RiExternalLinkLine />
                </a>
              </h2>
              <p>{t("about_us_digital_body")}</p>
            </div>
          </div>
        </div>
        <div>
          <img src={Products} alt="products" />
          <div
            className="services-card even-card"
            id="services-card-2"
            data-aos="fade-left"
            data-aos-anchor="#services-card-1"
          >
            <div>
              <h1>2</h1>
            </div>
            <div>
              <h2>
                <a
                  href="/Shop"
                  onClick={async (e) => {
                    e.preventDefault();
                    await AnalyticsService.gaEvent(
                      "Home-service",
                      `Link Redirected`,
                      `Chatbot Link`,
                      // `Products Link`,
                      1
                    );
                    // history.push("/Shop");
                    // window.open("/Chatbot?enterprise=SWS", "_self");
                    window.open("/Chatbot", "_self");
                  }}
                  className="not-anchor onhover-limegreen"
                >
                  {t("about_us_product_title")} <RiExternalLinkLine />
                </a>
              </h2>
              <p>{t("about_us_product_body")}</p>
            </div>
          </div>
        </div>
        <div>
          <img src={Podcast} alt="podcast" />
          <div
            className="services-card odd-card"
            id="services-card-3"
            data-aos="fade-right"
            data-aos-anchor="#services-card-2"
          >
            <div>
              <h1>3</h1>
            </div>
            <div>
              <h2 className="services-podcast">
                {t("about_us_podcast_title")} <RiExternalLinkLine />
              </h2>
              <p>{t("about_us_podcast_body")}</p>
            </div>
          </div>
        </div>
        <div>
          <img src={Consultancy} alt="consultancy" />
          <div
            className="services-card even-card"
            id="services-card-4"
            data-aos="fade-left"
            data-aos-anchor="#services-card-3"
          >
            <div>
              <h1>4</h1>
            </div>
            <div>
              <h2>
                <a
                  href="/Consultancy"
                  onClick={async (e) => {
                    e.preventDefault();
                    await AnalyticsService.gaEvent(
                      "Home-service",
                      `Link Redirected`,
                      `Chatbot Link`,
                      // `Consultancy Link`,
                      1
                    );
                    // history.push("/Consultancy");
                    // window.open("/Chatbot?enterprise=SWS", "_self");
                    window.open("/Chatbot", "_self");
                  }}
                  className="not-anchor onhover-orange"
                >
                  {t("about_us_professional_title")} <RiExternalLinkLine />
                </a>
              </h2>
              <p>{t("about_us_professional_body")}</p>
            </div>
          </div>
        </div>
        <div>
          <img src={ChatbotLogo} alt="chatbot" />
          <div
            className="services-card odd-card"
            id="services-card-5"
            data-aos="fade-right"
            data-aos-anchor="#services-card-4"
          >
            <div>
              <h1>5</h1>
            </div>
            <div>
              <h2 className="services-chatbot">
                <a
                  // href="/Chatbot?enterprise=SWS"
                  href="/Chatbot"
                  onClick={async (e) => {
                    e.preventDefault();
                    await AnalyticsService.gaEvent(
                      "Home-service",
                      `Link Redirected`,
                      `Chatbot Link`,
                      1
                    );
                    // window.open("/Chatbot?enterprise=SWS", "_self");
                    window.open("/Chatbot", "_self");
                  }}
                  className="not-anchor"
                >
                  Mae: <br />
                  {t("about_us_mae_title")} <RiExternalLinkLine />
                </a>
              </h2>
              <p>{t("about_us_mae_body")}</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
