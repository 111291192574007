import React, { Component } from "react";
import { FaFacebookF } from "react-icons/fa";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

class FacebookButton extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    responseFacebook(res) {
        console.log("Facebook Credentials...");
        console.log(res);
        alert("Logged in as " + res.name)
    }
    render() {
        return (
            <FacebookLogin
                appId="387686968955209"
                // autoLoad
                autoLoad={false}
                fields="name,email,picture"
                callback={this.responseFacebook}
                isDisabled={true}
                render={renderProps => (
                    <button className="btn rounded-circle shadow text-blue grayscale" onClick={renderProps.onClick} disabled={renderProps.isDisabled}>
                        <FaFacebookF />
                    </button>
                )}
            />
        );
    }
}

export default FacebookButton;