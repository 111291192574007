const { default: axios } = require("axios");

export default {
  getAllQuestions: async (enterpriseCategory) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/bot/find?enterprise_name=${enterpriseCategory}`
      );
      if (res) {
        return { status: 1, data: res.data };
      }
      return { status: 0, data: null };
    } catch (error) {
      console.error(error);
      return { status: 0, data: null };
    }
  },
  getQAByCategory: async (categoryValue) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/bot/findBy`,
        {
          category: categoryValue,
        }
      );
      if (res) {
        return { status: 1, data: res.data };
      }
      return { status: 0, data: null };
    } catch (error) {
      console.error(error);
      return { status: 0, data: null };
    }
  },
  getVideosByCategory: async (categoryValue) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/video/findBy`,
        [
          {
            category: categoryValue,
          },
        ]
      );
      if (res) {
        return { status: 1, data: res.data };
      }
      return { status: 0, data: null };
    } catch (error) {
      console.error(error);
      return { status: 0, data: null };
    }
  },
  getQueryByQuestion: async (
    question,
    age,
    gender,
    race,
    enterpriseCategory
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/bot/query`,
        {
          question,
          answer: "",
          category: "",
          age,
          gender,
          race,
          enterprise_name: enterpriseCategory,
        }
      );
      if (res) {
        return { status: 1, data: res.data };
      }
      return { status: 0, data: null };
    } catch (error) {
      console.error(error);
      return { status: 0, data: null };
    }
  },
  reportQuestion: async (user_id, question_posted, enterprise_name) => {
    try {
      const reportObject = {
        user_id,
        question_posted,
        questions_List: [],
        enterprise_name,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/reports/insert`,
        reportObject
      );
      if (res) {
        return { status: 1, data: res.data };
      }
      return { status: 0, data: null };
    } catch (error) {
      console.error(error);
      return { status: 0, data: null };
    }
  },
  saveChatUser: async (payload) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/chatusers/insert`,
        payload
      );
      if (res) {
        // console.log("Chat User Logged");
        return res;
      }
      return { status: 0, data: null };
    } catch (error) {
      console.error(error);
      return { status: 0, data: null };
    }
  },
  fireFBEvent: async (payload) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/events/fire`,
        payload
      );
      if (res) {
        return res;
      }
      return { status: 0, data: null };
    } catch (error) {
      console.error(error);
      return { status: 0, data: null };
    }
  },
};
