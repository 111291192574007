import {
  SIGNED_IN,
  SIGNED_OUT,
  SHOW_MODAL,
  TOGGLE_AUTH_SCREEN,
} from "../constant";
import { bake_cookie, delete_cookie } from "sfcookies";

let defaultLogin = {
  showModal: false,
  isUserLogged: false,
  user: {},
  authScreen: "login",
};

const loginReducer = (state = defaultLogin, action) => {
  const { user, showModal, authScreen } = action;
  switch (action.type) {
    case TOGGLE_AUTH_SCREEN:
      return {
        ...state,
        authScreen: authScreen,
      };
    case SHOW_MODAL:
      return {
        ...state,
        showModal: showModal,
      };
    case SIGNED_IN:
      state.isUserLogged = true;
      state.user = user;
      // console.log('Login_state', state);
      bake_cookie("user", state);
      return state;
    case SIGNED_OUT:
      state.isUserLogged = false;
      state.user = {};
      // console.log('Login_state', state);
      delete_cookie("user");
      return state;
    default:
      return state;
  }
};
export default loginReducer;
