import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { setAuthScreen } from "../../actions/loginActions";
import ToastUtil from "../../Services/ToastUtil";
import "react-toastify/dist/ReactToastify.css";
// import { toast, ToastContainer } from 'react-toastify';

const LoginButton = styled.button`
  padding: 4px;
  width: 100%;
  background: black;
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins";
  border: none;
  box-shadow: 0 3px 5px 2px #9f9d9d;
  margin-top: 10px;

  &:hover {
    background: white;
    color: black;
  }
`;

const LoginInputs = styled.input`
  background: transparent;
  border: none;
  border-bottom: 2px solid #959c9c;
  color: black;
  padding: 0.25em 1em;
  width: 100%;
  font-family: "Poppins";
  font-size: 14px;

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.primary &&
    css`
      border-bottom: 2px solid palevioletred;
      color: palevioletred;
    `};
`;
const LoginHeading = styled.h4`
  text-align: center;
  color: black;
`;

class AuthContainer2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: {
        fname: "",
        lname: "",
      },
      email: "",
      contact: "",
      password: "",
    };
  }

  validateData(obj) {
    for (var key in obj) {
      if (typeof obj[key] != "object") {
        if (obj[key] === null || obj[key] === "") {
          return false;
        }
      }
    }
    return true;
  }

  btnClick(event) {
    event.preventDefault();
    console.log("Trying to register..");
    const formData = this.state;
    if (
      this.validateData(formData) &&
      this.state.name.fname &&
      this.state.name.lname
    ) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/user/register`, {
          name: {
            fname: formData.name.fname,
            lname: formData.name.lname,
          },
          email: formData.email,
          contact: formData.contact,
          password: formData.password,
        })
        .then((response) => {
          if (response.status === 200) {
            ToastUtil.popUpMessage("Successfully Registered");
          } else {
            ToastUtil.popUpMessage("Email Address already exist!");
          }
          // console.log(response);//testing..
        })
        .catch((error) => {
          // console.log(error);
          // console.log(error.response);
          if (error && error.response.status === 409) {
            ToastUtil.popUpMessage("Email Address already exist!");
          } else {
            ToastUtil.popUpMessage("Oops Something Went Wrong.");
          }
        });
    } else {
      ToastUtil.popUpMessage("Kindly fill all field!");
    }
  }

  render() {
    return (
      <Row>
        <Col md={12} className="px-5">
          <LoginHeading>Sign Up</LoginHeading>
          <LoginInputs
            placeholder="Enter First Name"
            onChange={(e) => {
              let fname = e.target.value;
              this.setState((prevState) => ({
                name: {
                  ...prevState.name,
                  fname: fname,
                },
              }));
            }}
            value={this.state.name.fname}
            required
          ></LoginInputs>
          <LoginInputs
            placeholder="Enter Family Name"
            onChange={(e) => {
              let lname = e.target.value;
              this.setState((prevState) => ({
                name: {
                  ...prevState.name,
                  lname: lname,
                },
              }));
            }}
            value={this.state.name.lname}
          ></LoginInputs>
          <LoginInputs
            placeholder="Enter Contact Number"
            onChange={(e) => this.setState({ contact: e.target.value })}
            value={this.state.contact}
            required
          ></LoginInputs>
          <LoginInputs
            type="email"
            placeholder="Enter Email Address"
            onChange={(e) => this.setState({ email: e.target.value })}
            value={this.state.email}
            required
          ></LoginInputs>
          <LoginInputs placeholder="Enter Password"></LoginInputs>
          <LoginInputs
            placeholder="Confirm Password"
            type="password"
            onChange={(e) => {
              this.setState({ password: e.target.value });
            }}
            value={this.state.password}
            required
          ></LoginInputs>
          <LoginButton onClick={(e) => this.btnClick(e)}>Register</LoginButton>
          <LoginButton
            onClick={() => this.props.dispatch(setAuthScreen("login"))}
          >
            Already Signed?
          </LoginButton>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.LoginReducer,
  };
}

export default connect(mapStateToProps, null)(AuthContainer2);
// export default AuthContainer2;
