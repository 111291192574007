import { CHATBOT_TOPICS } from "../../types/chatbot";

const linked_followups = [
  {
    fid: "1",
    questions: "What is the first symptom of pregnancy?",
  },
  {
    fid: "2",
    questions: "How do I deal with someone pressuring me to have sex?",
  },
  {
    fid: "3",
    questions: "What is consent?",
  },
];

export default [
  {
    category: Object.values(CHATBOT_TOPICS),
    list: linked_followups,
  },
];
