import React, { Component } from "react";
import "./style.css";
import company_logo from "../../Assets/Images/Company_Logo.png";
import { Col, Row } from "react-bootstrap";
import AuthContainer from "./AuthContainer";
import AuthContainer2 from "./AuthContainer2";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { FaRegWindowClose } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { setShowModal } from "../../actions/loginActions";

class LoginModal extends Component {
  render() {
    return (
      <div className="login-modal-main shadow">
        <Row className="mx-0 justify-content-center h-100">
          <Col
            sm={10}
            md={6}
            className="left-Col py-4 d-flex align-items-center"
          >
            <div className="text-center w-100">
              <div>
                <img
                  alt="company logo"
                  src={company_logo}
                  width="100%"
                  style={{ marginTop: "-1%", marginBottom: "-2%" }}
                  className="d-inline-block align-top"
                />
              </div>
              <p className="title3 px-1">
                "A 21st-century sexual wellness platform"
              </p>
            </div>
          </Col>
          <Col sm={10} md={6} className="right-Col py-2">
            {/* <FaRegWindowClose className="close cursorOnHover" onClick={() => this.props.setloginShow(false)} /> */}
            <FaRegWindowClose
              className="close cursorOnHover"
              onClick={() => this.props.dispatch(setShowModal(false))}
            />
            <SwitchTransition>
              <CSSTransition
                key={this.props.login.authScreen}
                addEndListener={(node, done) =>
                  node.addEventListener("transitionend", done, {
                    passive: false,
                  })
                }
                classNames="fade"
              >
                <div className="mt-5">
                  {this.props.login.authScreen === "login" ? (
                    <AuthContainer />
                  ) : (
                    <AuthContainer2 />
                  )}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.LoginReducer,
  };
}

export default connect(mapStateToProps, null)(LoginModal);
