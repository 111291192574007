import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import Heading from "../Reusable/Heading";
import "./style.css";

//import images
import YoungWoman from "../../Assets/Images/YoungWoman.jpg";
import PrimaryEducation from "../../Assets/Images/PrimaryEducation.jpg";
import Expert from "../../Assets/Images/Expert.jpg";
import Parents from "../../Assets/Images/Parents.jpg";

export default () => {
  const { t } = useTranslation();

  let count = "03";
  let heading = t('about_us_programs_title');
  let title = t('about_us_programs_body');

  return (
    <React.Fragment>
      <div id="programs" className="bg-image2 flx">
        {/* <div> */}
        <Heading count={count} heading={heading} title={title} />
        <div style={{ paddingBottom: "4%", paddingTop: "3%" }}>
          {/* <CardDeck> */}
          <div className="container-fluid" style={{ maxWidth: "1140px" }}>
            <div className="row">
              <div className="col-lg-3 col-md-6" style={{ marginBottom: "20px" }}
                data-aos="fade-down-right"
                data-aos-anchor="#programs"
              >
                <Card
                  className="h-100"
                  style={{
                    backgroundColor: "rgba(255,255,255,0)",
                    border: "transparent",
                    width: "100%",
                  }}
                >
                  <Card.Img
                    style={{
                      Width: "30vw",
                      height: "auto",
                      maxWidth: "300px",
                      alignSelf: "center",
                    }}
                    variant="top"
                    src={YoungWoman}
                    alt="Young Woman"
                  />
                  <Card.Body style={{ padding: 0 }}>
                    <Card.Title style={{ color: "#00aaf2" }}>
                      {t('about_us_you_title')}
                    </Card.Title>
                    <Card.Text
                      style={{
                        textAlign: "justify",
                        color: "#e4e1e1",
                        fontFamily: "'Poppins',sans-serif",
                        fontSize: "15px",
                        fontWeight: "400",
                        marginBottom: "20px",
                      }}
                    >
                      {t('about_us_you_body')}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer style={{ color: "#0366d6" }}>
                    <small>{t('about_us_know_more')}</small>
                  </Card.Footer>
                </Card>
              </div>
              <div className="col-lg-3 col-md-6" style={{ marginBottom: "20px" }}
                data-aos="fade-down-right"
                data-aos-delay="300"
                data-aos-anchor="#programs"
              >
                <Card
                  className="h-100"
                  style={{
                    backgroundColor: "rgba(255,255,255,0)",
                    border: "transparent",
                    width: "100%",
                  }}
                >
                  <Card.Img
                    style={{
                      Width: "30vw",
                      height: "auto",
                      maxWidth: "300px",
                      alignSelf: "center",
                    }}
                    variant="top"
                    src={Parents}
                    alt="Parents"
                  />
                  <Card.Body style={{ padding: 0 }}>
                    <Card.Title style={{ color: "#6ee0c5" }}>
                      {t('about_us_parents_title')}
                    </Card.Title>
                    <Card.Text
                      style={{
                        textAlign: "justify",
                        color: "#e4e1e1",
                        fontFamily: "'Poppins',sans-serif",
                        fontSize: "15px",
                        fontWeight: "400",
                        marginBottom: "20px",
                      }}
                    >
                      {t('about_us_parents_body')}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer style={{ color: "#0366d6" }}>
                    <small>{t('about_us_know_more')}</small>
                  </Card.Footer>
                </Card>
              </div>
              <div className="col-lg-3 col-md-6" style={{ marginBottom: "20px" }}
                data-aos="fade-down-right"
                data-aos-delay="600"
                data-aos-anchor="#programs"
              >
                <Card
                  className="h-100"
                  style={{
                    backgroundColor: "rgba(255,255,255,0)",
                    border: "transparent",
                    width: "100%",
                  }}
                >
                  <Card.Img
                    style={{
                      Width: "30vw",
                      height: "auto",
                      maxWidth: "300px",
                      alignSelf: "center",
                    }}
                    variant="top"
                    src={PrimaryEducation}
                    alt="primary education"
                  />
                  <Card.Body style={{ padding: 0 }}>
                    <Card.Title style={{ color: "#9c54f9" }}>
                      {t('about_us_schools_title')}
                    </Card.Title>
                    <Card.Text
                      style={{
                        textAlign: "justify",
                        color: "#e4e1e1",
                        fontFamily: "'Poppins',sans-serif",
                        fontSize: "15px",
                        fontWeight: "400",
                        marginBottom: "20px",
                      }}
                    >
                      {t('about_us_schools_body')}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer style={{ color: "#0366d6" }}>
                    <small>{t('about_us_know_more')}</small>
                  </Card.Footer>
                </Card>
              </div>
              <div className="col-lg-3 col-md-6" style={{ marginBottom: "20px" }}
                data-aos="fade-down-right"
                data-aos-delay="900"
                data-aos-anchor="#programs"
              >
                <Card
                  className="h-100"
                  style={{
                    backgroundColor: "rgba(255,255,255,0)",
                    border: "transparent",
                    width: "100%",
                  }}
                >
                  <Card.Img
                    style={{
                      Width: "30vw",
                      height: "auto",
                      maxWidth: "300px",
                      alignSelf: "center",
                    }}
                    variant="top"
                    src={Expert}
                    alt="expert"
                  />
                  <Card.Body style={{ padding: 0 }}>
                    <Card.Title style={{ color: "#f38a18" }}>
                      {t('about_us_experts_title')}
                    </Card.Title>
                    <Card.Text
                      style={{
                        textAlign: "justify",
                        color: "#e4e1e1",
                        fontFamily: "'Poppins',sans-serif",
                        fontSize: "15px",
                        fontWeight: "400",
                        marginBottom: "20px",
                      }}
                    >
                      {t('about_us_experts_body')}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer style={{ color: "#0366d6" }}>
                    <small>{t('about_us_know_more')}</small>
                  </Card.Footer>
                </Card>
              </div>
            </div>
          </div>
          {/* </CardDeck> */}
        </div>
        {/* </div> */}
      </div>
    </React.Fragment>
  );
};
