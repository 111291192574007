import Axios from "axios";

const authAxios = Axios.create();

authAxios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return config;
  },
  (error) => {
    // console.log("error in request", error);
    return Promise.reject(error);
  }
);
authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if(error.response.status === 401){
    //   return (<Portal><Login /></Portal>)
    // }

    if (error.response.status === 403) {
      const data = {
        token: localStorage.getItem("refreshToken"),
        email: localStorage.getItem("email"),
      };
      // return Axios.post("https://backend.standwespeak.com/users/token", data)
      // console.log(data);
      return Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/refreshToken`,
        data
      )
        .then((res) => {
          // console.log('New Access Token', res);
          if (res.data.new_accessToken) {
            localStorage.setItem("accessToken", res.data.new_accessToken);
            const config = error.response.config;
            config.headers["authorization"] = `Bearer ${localStorage.getItem(
              "accessToken"
            )}`;

            return new Promise((resolve, reject) => {
              Axios.request(config)
                .then((response) => {
                  // console.log("return axios request response:", response);
                  resolve(response);
                })
                .catch((error) => {
                  console.log("return request failed with error:", error);
                  reject(error);
                });
            });
          }
        })
        .catch((error) => {
          console.log("error getting new access token!");
          Promise.reject(error);
        });
    }
  }
);

export default authAxios;
