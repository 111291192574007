import React, { Component } from "react";
import { redirectToSiteSelf } from "../../Utils/util";
import FourZeroFourImage from "../../Assets/Images/FourZeroFour.png";

class FourZeroFour extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div>
                <img
                    style={{ width: "60%", height: "80vh", cursor: "pointer", margin: "auto", padding: "50px 0px" }}
                    onClick={() => redirectToSiteSelf("/")}
                    // onClick={() => this.props.push("/")}
                    title="Click to go to Home Page"
                    src={FourZeroFourImage}
                    alt="Click to go to home page" />
            </div>
        );
    }
}

export default FourZeroFour;