import { CHATBOT_TOPICS } from "../../types/chatbot";

const linked_resources = [
  {
    rid: 1,
    resources_title: "FindMyMethod",
    resources_subtitle: "Contraception",
    resources_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/findmymethod-contraceptive.png",
    resources_url: "FindMyMethod.org",
  },
  {
    rid: 2,
    resources_title: "Safe2Choose",
    resources_subtitle: "Safe Abortion",
    resources_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/safe2choose.png",
    resources_url: "Safe2choose.org",
  },
  {
    rid: 3,
    resources_title: "Centers for Disease Control and Prevention",
    resources_subtitle: "STDs",
    resources_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/STDs.png",
    resources_url: "https://www.cdc.gov/std/",
  },
  {
    rid: 4,
    resources_title: "The Trevor Project",
    resources_subtitle: "Crisis Intervention for LGBTQ+",
    resources_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/trevor+project.png",
    resources_url: "https://www.thetrevorproject.org/about/",
  },
  {
    rid: 5,
    resources_title: "Centre for Reproductive Rights",
    resources_subtitle: "Abortion",
    resources_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Resources/centre+for+reproductive+rights.png",
    resources_url:
      "https://maps.reproductiverights.org/worldabortionlaws?category[294]=294",
  },
  {
    rid: 6,
    resources_title: "SheSays",
    resources_subtitle: "Abuse",
    resources_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Resources/She+Says.png",
    resources_url: "https://www.shesays.in/",
  },
  {
    rid: 7,
    resources_title: "How to use Abortion Pill",
    resources_subtitle: "Abortion",
    resources_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Resources/how+to+use+abortion+pill.png",
    resources_url: "HowtoUseAbortionPill.org",
  },
  {
    rid: 8,
    resources_title: "Sneha Mumbai",
    resources_subtitle: "Domestic Violence",
    resources_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Resources/Sneha+Mumbai.png",
    resources_url: "https://snehamumbai.org/domestic-violence/",
  },
  {
    rid: 9,
    resources_title: "National Commission for Women",
    resources_subtitle: "Laws",
    resources_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Resources/National+Commission+for+Women.png",
    resources_url:
      "http://ncw.nic.in/important-links/List-of-Laws-Related-to-Women",
  },
  {
    rid: 10,
    resources_title: "National AIDS Control Organisation",
    resources_subtitle: "STDs",
    resources_image:
      "https://chatbotservicesproducts.s3.ap-south-1.amazonaws.com/Resources/National+AIDS+Control+org.png",
    resources_url: "http://naco.gov.in/  ",
  },
];

export default [
  {
    category: Object.values(CHATBOT_TOPICS),
    list: linked_resources,
  },
];
