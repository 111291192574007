import "./wdyr"; // <--- first import

import "react-hot-loader";
import { hot } from "react-hot-loader/root";

import React from "react";
import ReactDOM from "react-dom";
// import TagManager from "react-gtm-module";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

//import store
import Store from "./Store";
import { Provider } from "react-redux";

const HotApp = hot(App);
HotApp.whyDidYouRender = true;

// const tagManagerArgs = {
//   gtmId: "GTM-5V82HGC",
// };

// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={Store}>
    <HotApp />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
