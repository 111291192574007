import React, { useEffect } from "react";
import "./style.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import Footer from "../../Components/Footer";

import Priyal from "../../Assets/Images/Priyal(400x400).jpg";

import Ishanika from "../../Assets/Images/Ishanika(300x300).jpg";
import Abhishek from "../../Assets/Images/Abhishek(300x300).jpg";
import Simran from "../../Assets/Images/Simran(300x300).jpg";
import Anshul from "../../Assets/Images/Anshul(300x300).jpg";
import Grishma from "../../Assets/Images/Grishma(300x300).jpg";
import Mohana from "../../Assets/Images/Mohana(300x300).jpg";
import Priyanka from "../../Assets/Images/Priyanka(300x300).jpg";
// import Muskaan from "../../Assets/Images/Muskaan(300x300).jpeg";
import Bhavika from "../../Assets/Images/Bhavika(300x300).jpeg";
import Hardik from "../../Assets/Images/Hardik(300x300).jpg";
// import Ishika from "../../Assets/Images/Ishika(300x300).jpg";
// import Zainab from "../../Assets/Images/Zainab(300x300).jpg";
// import Shalvi from "../../Assets/Images/Shalvi(300x300).jpg";
// import GIM from "../../Assets/Images/GIM.png";
// import HUB from "../../Assets/Images/HUB.jpg";

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="bg-team flx">
        <div className="flx">
          <h1>One Team, One Dream!</h1>
          <p>
            "Coming together in the beginning, staying together in the process,
            working together for the success."
          </p>
        </div>
      </div>
      <div style={{ width: "100%", height: "2px" }}></div>
      <div className="bg-founder">
        <div>
          <div className="heading-container">
            <h1 className="flx" style={{ fontSize: "115px" }}>
              Our Founder
            </h1>
            <h3 style={{ color: "white" }}>The Founder</h3>
          </div>
          <p
            className="tag-line"
            style={{ color: "white", marginTop: "-18px" }}
          >
            "You don't have to know how to do it. You just have to know that you can." - Bob Proctor
          </p>
        </div>
        <Container
          fluid
          style={{
            maxWidth: "1140px",
            marginTop: "150px",
            marginBottom: "150px",
          }}
        >
          <Row>
            <Col
              lg={"4"}
              className="founder-img"
              style={{ paddingRight: "0px" }}
            >
              <img src={Priyal} alt="Priyal" />
            </Col>
            <Col lg={"8"}>
              <Card className="card-founder">
                <p>
                  Priyal Agrawal is passionate about ensuring that young people
                  have access to information and sexual health services,
                  required to gain complete control over their bodies and lives.
                </p>
                <p>
                  Her journey – from a naïve little girl to a free-thinking
                  millennial and now a social changemaker – was only facilitated
                  by her education and background in Psychology; her experiences
                  in social and mental health industry; the times when she met
                  people from different backgrounds facing similar issues; and
                  exposure to the prevailing orthodox culture, misbeliefs and
                  sexual health practices in India.
                </p>
                <p>
                  Dedicated and devoted entirely to bring a paradigm shift in
                  the mindset of people and the way children receive sex
                  education around the globe; Priyal founded StandWeSpeak with a
                  vision and aim to promote social and gender equality and
                  create a positive systematic change in the society.
                </p>
                <h6>PRIYAL AGRAWAL</h6>
                <p style={{ marginBottom: "0px", color: "#2b2b2b" }}>
                  Founder & CEO
                </p>
              </Card>
              <div id="card-founder-gradient-bottom"></div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <div>
          <div className="heading-container">
            <h1 className="flx">Core Team</h1>
            <h3>Core Team</h3>
          </div>
          <p className="tag-line">
            Meet the team behind this initiative, who are passionate about
            bringing a positive change in people's lives.
          </p>
        </div>
        <Container fluid className="core-team-container">
          <Row>
            <Col md={"3"} sm={"12"}>
              <img src={Ishanika} alt="Ishanika" />
              <strong>ISHANIKA SEGHAL</strong>
              <p style={{ color: "#d62e2e" }}>Marketing Head</p>
              <p>
                Ishanika Sehgal is a Marketing and Social Media enthusiast . She
                has completed her MBA focused in Digital Marketing from FLAME
                University. She has worked with NGO’s regarding environment,
                animals, sanitization for women and education for the
                underprivileged, being an executive of Students of Social
                Reforms initiative club, Sophia college Mumbai.
              </p>
            </Col>
            <Col md={"3"} sm={"12"}>
              <img src={Abhishek} alt="Abhishek" />
              <strong>ABHISHEK SHARMA</strong>
              <p style={{ color: "#13cba9" }}>Web Developer</p>
              <p>
                Abhishek is a Computer Science Engineer, currently working on
                mobile and web development technologies. He is a sports
                enthusiast and an adventure loving person, who is always up for
                one. Fascinated by technology, he believes it has made a major
                impact in our society and the impact is ever-growing. He is also
                interested in human behaviour and psychology and believes true
                change can be brought by educating people on every aspect of
                life.
              </p>
            </Col>
            <Col md={"3"} sm={"12"}>
              <img src={Simran} alt="Simran" />
              <strong>SIMRAN AULAKH</strong>
              <p style={{ color: "#962bbf" }}>Social Media Manager</p>
              <p>
                Confident, self-driven and passionate about her work, Simran
                Aulakh is an English literature graduate. She is someone who not
                only writes but truly believes in the power every word said or
                read bears. Coming from an open-minded background, she aspires
                to introduce the world to the possibilities that can be achieved
                and shatter the stereotypes binding us from exploring new
                horizons.
              </p>
            </Col>
            <Col md={"3"} sm={"12"}>
              <img src={Anshul} alt="Anshul" />
              <strong>ANSHUL SINGH</strong>
              <p style={{ color: "#f4a92d" }}>Team Lead</p>
              <p>
                Anshul is currently engaged as Startup Ecosystem Lead for Atal
                Incubation Centre, GIM Under NITI Aayog’s AIM and Mentor of
                Change for Atal Innovation Mission. He is an Ex- Entrepreneur
                with 4 years of entrepreneurial experience of founding two
                startups; Both Funded. He also has experience in raising funds
                and managing early-stage operations.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={"3"} sm={"12"}>
              <img src={Grishma} alt="Grishma" />
              <strong>GRISHMA ASHAR</strong>
              <p style={{ color: "#d62e2e" }}>Asso. Curriculum Developer</p>
              <p>
                Grishma Ashar is an upcoming psychologist who is known for her
                work in NGOs related to mental health and child education.
                Currently pursuing her Master’s in Counseling Psychology from
                SNDT Matunga, this youngster, is an advocate for women and
                LGBTQ+ rights. She is a national chess gold medalist and the
                exposure from the game made her realise the level at which
                patriarchy is ingrained in our culture. It is her belief that
                through sex education and gender studies, equal rights for
                everyone can be achieved.
              </p>
            </Col>
            <Col md={"3"} sm={"12"}>
              <img src={Mohana} alt="Mohana" />
              <strong>MOHANA SOMAN</strong>
              <p style={{ color: "#13cba9" }}>Content Writer</p>
              <p>
                Hailing from the Mumbai itself, Mohana Soman is a 20 year old
                student who is still in the process of graduating from Sophia
                College Autonomous. Her focus was Literature, and her passion
                for arguing about very minute details most people don’t
                remember, is unlimited. She wants her writing to make a
                difference, wants it to mean something for anyone. Also, we know
                that her picture doesn’t suggest it, but she can smile.
              </p>
            </Col>
            <Col md={"3"} sm={"12"}>
              <img src={Priyanka} alt="Priyanka" />
              <strong>PRIYANKA GULATI</strong>
              <p style={{ color: "#962bbf" }}>Content Writer</p>
              <p>
                Priyanka is a second year mass media student. Her respect, for
                the significance that words carry in shaping our beliefs, stems
                from her passion for our social, political, environmental, and
                economic ecosystem. She spends most of her free time browsing
                and learning from the digital world. She believes that when the
                digital media is consumed correctly it can generate great
                thinkers. She is a feminist, environmentalist and a
                philanthropist in progress.
              </p>
            </Col>
            <Col md={"3"} sm={"12"}>
              <img src={Bhavika} alt="Bhavika" />
              <strong>BHAVIKA AGGARWAL</strong>
              <p style={{ color: "#f4a92d" }}>Research Associate</p>
              <p>
                She is Bhavika Aggarwal, currently pursuing organisational
                psychology from Amity university, noida. She did her higher
                studies in commerce. She always wanted to do something different
                so opted for psychology. Bhavika has done her internships in
                various organisations like Fortis Escorts Heart Institute,
                Zenith Consultants, World Brain Centre, Deepali Batra clinic in
                child psychology and Moolchand Hospital and also attended
                various workshops. Bhavika is learning french from Alliance
                Française de Delhi. She loves to travel and explore new places
                and cuisines.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={"3"} sm={"12"}>
              <img src={Hardik} alt="Hardik" />
              <strong>HARDIK AHUJA</strong>
              <p style={{ color: "#d62e2e" }}>Research Associate</p>
              <p>
                Hardik is currently involved in various academic and
                non-academic research projects based on mental health and
                psychometric tool development. He is also a finalist of Anveshan
                National Student Research Convention, 2020. He is innovative and
                robust in research designs and literature writing, with prior
                experience in guidance and counselling. His work experience also
                includes working on rehabilitative projects for prisoners. With
                frequent social work involvement, he has built great networking
                with various Social Work NGOs and organisations.{" "}
              </p>
            </Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
      <Footer maxWidth="97vw" />
    </React.Fragment>
  );
};
