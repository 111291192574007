import Popup from "../../Components/Popup";
import { Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import "./style.css";

import TeamService from "../../Services/Team";
import AnalyticsService from "../../Services/Analytics";

const TeamMember = (props) => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Col
      md="4"
      sm="6"
      style={{ display: "flex", justifyContent: "center", marginTop: "80px" }}
    >
      <div className="team-member flx">
        <img
          src={props.member.thumbnail}
          alt={props.member.name}
          onClick={async () => {
            await AnalyticsService.gaEvent(
              "Team-core",
              `open dialogue`,
              `know more about ${props.member.name}`,
              1
            );
            setModalShow(true);
          }}
        />
        <p>{props.member.name}</p>
        <p>{props.member.designation}</p>
        <p
          style={{ color: "blue", cursor: "pointer" }}
          onClick={async () => {
            await AnalyticsService.gaEvent(
              "Team-core",
              `open dialogue`,
              `know more about ${props.member.name}`,
              1
            );
            setModalShow(true);
          }}
        >
          Know more
        </p>
        <Popup show={modalShow} onHide={() => setModalShow(false)} {...props} />
      </div>
    </Col>
  );
};

export default (props) => {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    TeamService.getMembers(setTeam);
  }, []);

  return (
    <React.Fragment>
      <div>
        <div>
          <div className="heading-container">
            <h1 className="flx">Core Team</h1>
            <h3>Core Team</h3>
          </div>
          <p className="tag-line">
            Meet the team behind this initiative, who are passionate about
            bringing a positive change in people's lives.
          </p>
        </div>
        <div className="container-fluid ">
          <div className="row">
            {team &&
              team.map((member, i) => {
                return <TeamMember key={i} member={member} {...props} />;
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
