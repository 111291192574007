import React from "react";
import { withTranslation } from "react-i18next";
import AnalyticsService from "../../Services/Analytics";
import "./style.css";

class GetInTouch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      subject: "",
      message: "",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = async (event) => {
    alert(this.props.t("get_in_touch_alert"));
    var body = this.state;
    await AnalyticsService.gaEvent(
      "Home-contact",
      `Sent Inquiry`,
      `Contact Details submitted of ${this.state.name}`,
      1
    );
    fetch(`${process.env.REACT_APP_BACKEND_URL}/app/contact`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // We convert the React state to JSON and send it as the POST body
      body: JSON.stringify(body),
    }).then(function (response) {
      // console.log(response)
      return response.json();
    });

    event.preventDefault();
  };

  render() {
    const { email, name, subject, message } = this.state;
    return (
      <form onSubmit={this.handleSubmit} style={{ maxWidth: "800px" }}>
        <div className="container p-0">
          <div className="row">
            <div className="col-md-6">
              <input
                className="input"
                type="text"
                name="name"
                value={name}
                onChange={this.handleChange}
                placeholder={this.props.t("get_in_touch_form_name")}
                required
              />
            </div>
            <div className="col-md-6">
              <input
                className="input"
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                placeholder={this.props.t("get_in_touch_form_email")}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <input
                className="input"
                type="text"
                name="subject"
                value={subject}
                onChange={this.handleChange}
                placeholder={this.props.t("get_in_touch_form_subject")}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <textarea
                className="input"
                type="text"
                name="message"
                value={message}
                onChange={this.handleChange}
                placeholder={this.props.t("get_in_touch_form_message")}
                required
                style={{ height: "150px" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button type="submit" className="btn-send-message">
                {this.props.t("get_in_touch_form_button")}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(GetInTouch);
