import i18n from '../Utils/translation'
import {
  ADD_NEW_MESSAGE,
  TOGGLE_DROPDOWN,
  USER_INFO,
  USER_INFO_RESET,
  REMOVE_LOADING_MESSAGE
} from '../constant'
import { getTime } from '../Utils/util'

// const sendReport = (uid, userQuestion) => {
//   swal
//     .fire({
//       title: "Do you want to send this question for enquiry?",
//       showDenyButton: true,
//       showCancelButton: false,
//       confirmButtonText: `Report`,
//       denyButtonText: `Go Back`,
//     })
//     .then(async (result) => {
//       if (result.isConfirmed) {
//         const res = await BotService.reportQuestion(uid, userQuestion);
//         console.log(res);
//         swal.fire("Sent!", "", "success");
//       } else if (result.isDenied) {
//         swal.fire("Hmmm No problem!", "", "info");
//       }
//     });
// };
//initializing state
const initialState = {
  tempUser: {
    greet: true,
    say1: false,
    name: '',
    country: '',
    age: '',
    gender: '',
    race: '',
    cuid: ''
    // test
    // say1: true,
    // name: "Steven",
    // country: "Africa",
    // age: "12",
    // gender: "Male",
    // race: "Asian",
  },
  bottomDropdown: false,
  chats: [
    // {
    //   origin: "show_videos_button",
    //   message: "",
    //   data: ["" | ""],
    //   time: getTime(),
    // }, // test
    // {
    //   origin: "show_videos",
    //   category: "Puberty",
    //   time: getTime(),
    // }, // test
    // {
    //   origin: "choice_questions",
    //   questions: [
    //     "What do I do if I have a crush on someone?",
    //     "How do I get over a crush?",
    //   ],
    //   time: getTime(),
    // }, // test
    // {
    //   origin: "bot",
    //   message: (
    //     <>
    //       <span>
    //         Hi I'm a chatbot.{" "}
    //         <FaQuestionCircle
    //           style={{ cursor: "pointer" }}
    //           onClick={() =>
    //             sendReport(
    //               "60b8831fc620b8356cb7a098",
    //               "What happens if my hands swell?"
    //             )
    //           }
    //         />
    //       </span>
    //     </>
    //   ),
    //   time: getTime(),
    // },
    // {
    //   origin: "user",
    //   message: "What do you know about consent?",
    //   time: getTime(),
    // },
    {
      origin: 'bot',
      message: i18n.t('chatbot_first_message'),
      time: getTime()
    },
    {
      origin: 'bot',
      message: i18n.t('chatbot_second_message'),
      time: getTime()
    }
  ]
}

const ChatReducer = (state = initialState, action) => {
  // console.log("Type: ", action.type);
  // console.log("Payload: ", action.payload);
  switch (action.type) {
    case ADD_NEW_MESSAGE:
      return {
        ...state,
        chats: [...state.chats, action.payload]
      }
    case REMOVE_LOADING_MESSAGE:
      state.chats = state.chats.filter((x) => x.origin !== 'bot_loading')
      return {
        ...state,
        chats: [...state.chats]
      }
    case TOGGLE_DROPDOWN:
      return {
        ...state,
        bottomDropdown: !state.bottomDropdown
      }
    case USER_INFO:
      return {
        ...state,
        tempUser: action.payload
      }
    case USER_INFO_RESET:
      return {
        ...state,
        tempUser: {
          greet: false,
          name: '',
          age: '',
          gender: '',
          race: ''
        }
      }
    default:
      return state
  }
}

export default ChatReducer
