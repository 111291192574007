import React from "react";
import { useTranslation } from "react-i18next";
import Heading from "../Reusable/Heading";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import { FaQuoteLeft, FaRegDotCircle } from "react-icons/fa";
import { IconContext } from "react-icons";

import AIC from "../../Assets/Images/AIC.jpg";
import GIM from "../../Assets/Images/GIM.jpg";
import Priya from "../../Assets/Images/PriyaAulakh.jpg";

import SchoolWorkshops from "../../Assets/Images/SchoolWorkshops.png";
import ParentSanitization from "../../Assets/Images/ParentSanitization.png";
import CorporateWorkshops from "../../Assets/Images/CorporateWorkshops.png";
import PositiveFeedbacks from "../../Assets/Images/PositiveFeedbacks.png";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

export default () => {
  const { t } = useTranslation();

  let count = "05";
  let heading = t('about_us_testimonials_title');
  let title = t('about_us_testimonials_body');

  return (
    <React.Fragment>
      <div id="testimonials" className="bg-image3">
        <div
          className="outer-container"
          style={{ backgroundColor: "transparent" }}
        >
          <Heading count={count} heading={heading} title={title} />
        </div>
        <Carousel className="carousels carousel-fade">
          <Carousel.Item>
            <div>
              <IconContext.Provider value={{ size: "30px", color: "#ccc" }}>
                <FaQuoteLeft name="Fa Quote" />
              </IconContext.Provider>
              <p style={{ margin: "40px 0" }}>
                I believe that developing a strong & healthy parent-child bond
                right from the beginning wherein the child doesn’t feel
                uncomfortable asking any question & gets genuine answers in a
                language that they understand at a given age is essential to
                keep them safe, make them feel empowered and feed their
                curiosity. As most kids have access to the internet these days,
                so if you try to keep the topic hush-hush, they will look for
                their answers on the internet which I feel will cause more harm
                than good.
              </p>
              <div className="testimonials-logo">
                <div>
                  <img src={Priya} alt="Priya Aulakh" />
                </div>
                <div>
                  <h6>Priya Aulakh</h6>
                  <p>Parent</p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <IconContext.Provider value={{ size: "30px", color: "#ccc" }}>
                <FaQuoteLeft name="Fa Quote" />
              </IconContext.Provider>
              <p style={{ margin: "40px 0" }}>
                It is important that individuals are taught about human sexual
                anatomy, it’s development & functions, and sexuality to be able
                to make well-informed choices & build sexual relationships based
                on respect for one another’s needs and boundaries, which also
                contributes to the prevention of sexual abuse and violence and
                misconduct.
              </p>
              <div className="testimonials-logo">
                <div>
                  <img src={AIC} alt="AIC" />
                </div>
                <div>
                  <h6>ATAL INCUBATION CENTRE, GIM, (NITI AAYOG, AIM)</h6>
                  <p>Management</p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <IconContext.Provider value={{ size: "30px", color: "#ccc" }}>
                <FaQuoteLeft name="Fa Quote" />
              </IconContext.Provider>
              <p style={{ margin: "40px 0" }}>
                We grew up with various myths and misconceptions about our own
                bodies, it’s functions, gender diversity, reproductive health
                and rights, STDs, etc. During our school time, even the teachers
                were so uncomfortable that they would skip the reproduction part
                of the chapter. It would really have been better if had a
                reliable source of information and someone to discuss about the
                same.
              </p>
              <div className="testimonials-logo">
                <div>
                  <img src={GIM} alt="GIM" />
                </div>
                <div>
                  <h6>GOA INSTITUTE OF MANAGEMENT</h6>
                  <p>Students</p>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        <Container style={{ marginBottom: "100px" }}>
          <Row className="work-done">
            <Col lg={"3"} md={"6"} sm={"12"}>
              <img src={SchoolWorkshops} alt="school workshops" />
              <p>{t('about_us_testimonials_card_1_rate')}</p>
              <p>{t('about_us_testimonials_card_1_title')}</p>
              <IconContext.Provider value={{ color: "#fb5f45", size: "20px" }}>
                <FaRegDotCircle />
              </IconContext.Provider>
            </Col>
            <Col lg={"3"} md={"6"} sm={"12"}>
              <img src={ParentSanitization} alt="parent sanitization" />
              <p>{t('about_us_testimonials_card_2_rate')}</p>
              <p>{t('about_us_testimonials_card_2_title')}</p>
              <IconContext.Provider value={{ color: "#38c595", size: "20px" }}>
                <FaRegDotCircle />
              </IconContext.Provider>
            </Col>
            <Col lg={"3"} md={"6"} sm={"12"}>
              <img src={CorporateWorkshops} alt="corporate workshops" />
              <p>{t('about_us_testimonials_card_3_rate')}</p>
              <p>{t('about_us_testimonials_card_3_title')}</p>
              <IconContext.Provider value={{ color: "#b27cf4", size: "20px" }}>
                <FaRegDotCircle />
              </IconContext.Provider>
            </Col>
            <Col lg={"3"} md={"6"} sm={"12"}>
              <img src={PositiveFeedbacks} alt="positive feedbacks" />
              <p>{t('about_us_testimonials_card_4_rate')}</p>
              <p>{t('about_us_testimonials_card_4_title')}</p>
              <IconContext.Provider value={{ color: "#fdb860", size: "20px" }}>
                <FaRegDotCircle />
              </IconContext.Provider>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
