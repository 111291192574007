import React from "react";
import "./style.css";

export default function Heading(props) {
  let count = props.count;
  let heading = props.heading;
  let title = props.title;
  let margin = props.margin;
  let titleColor = props.titleColor;
  let headingColor = props.headingColor;
  let titleMargin = props.titleMargin;

  return (
    <React.Fragment>
      <div className="reusable-heading px-5">
        <div>
          <div className="reusable-heading-count">
            <span>{count}</span>
          </div>
          <div
            className="reusable-heading-heading"
            style={{ margin: `${margin}` }}
          >
            <h1 style={{ color: `${headingColor}` }}>{heading}</h1>
          </div>
        </div>
        <div style={{ marginTop: `${titleMargin}` }}>
          <span style={{ color: `${titleColor}`, fontFamily: "inherit" }}>
            {title}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}

Heading.defaultProps = {
  margin: "7vw 0 0 0",
  titleColor: "#ffffff",
  headingColor: "#ffffff",
  titleMargin: "-12%",
};
