import React, { useEffect } from "react";
import AboutUS from "../../Components/AboutUS";
import Testimonials from "../../Components/Testimonials";
import Footer from "../../Components/Footer";
import Csse from "../../Components/Csse";
import Programs from "../../Components/Programs";
import Services from "../../Components/Services";
import { Helmet } from "react-helmet";
import "./style.css";
import Aos from "aos";

export default () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    Aos.init({
      duration: 1200,
      once: true,
      easing: "ease-in-out-back",
      anchorPlacement: "center-top",
    });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          A SexTech startup promoting gender equality | Mae | Sexual Health
        </title>
        <meta
          name="title"
          content="A SexTech startup promoting gender equality | Mae | Sexual Health"
        />
        <meta
          name="description"
          content="A 21st-century digital sexual wellness platform for youth. Empowering you to make informed decisions about your body, relationships, and mental health throughout your life."
        />
        <meta
          name="keywords"
          content="About StandWeSpeak, Abortion, Contraception, Pregnancy, Sexual health, Sexual Wellness, Menstruation, Abuse, Domestic Violence, Puberty, Pleasure, Gender, LGBTQ+, Sexuality, Consent, Relationships, Breakup, Violence, iPill, Chatbot, Periods, Porn, STDs, HIV, Miscarriage, Bullying, Sexologist, Women's doctor, Gynecologist."
        />
      </Helmet>
      {/* <FrontQuote /> */}
      <AboutUS />
      <Services />
      <Programs />
      <Csse />
      <Testimonials />
      <Footer maxWidth="97vw" />
    </React.Fragment>
  );
};
