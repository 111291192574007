import React, { useEffect } from "react";

//import pages
import GraphicDesigner from "./graphicDesigner";
import ContentWriter from "./contentWriter";
import AssociateResearcher from "./associateResearcher";

export default (props) => {
  const jobDescription = props.match.params.id;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      {jobDescription === "graphic" ? (
        <GraphicDesigner />
      ) : jobDescription === "content" ? (
        <ContentWriter />
      ) : (
            <AssociateResearcher />
          )}
    </React.Fragment>
  );
};
