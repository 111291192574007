import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

// Associated with
import OPSAGI from "../../Assets/Images/media/associatedWith/OPSAGI.png";
import Momentum from "../../Assets/Images/media/associatedWith/momentum.png";
import USAID from "../../Assets/Images/media/associatedWith/us_aid.png";
import Microsoft from "../../Assets/Images/media/associatedWith/microsoft_for_startups.webp";
import LINC from "../../Assets/Images/media/associatedWith/linc.png";
import UNDP from "../../Assets/Images/media/associatedWith/undp_logo.png";
import UNLEASH from "../../Assets/Images/media/associatedWith/unleashlogo.png";
import STARTUP from "../../Assets/Images/media/associatedWith/startup_logo.png";
import KSGC from "../../Assets/Images/media/associatedWith/ksgc_logo.png";

// import GIM from "../../Assets/Images/media/associatedWith/gim.jpg";
// import WEBHUB from "../../Assets/Images/media/associatedWith/webhub.png";
// import ASHOKA from "../../Assets/Images/media/associatedWith/ashoka.png";

// partners
import amaze from "../../Assets/Images/media/partners/amaze.jpg";
import fmm from "../../Assets/Images/media/partners/fmm.png";
import htu from "../../Assets/Images/media/partners/htu.png";
import outspoken from "../../Assets/Images/media/partners/outspoken-sexed.png";
import period from "../../Assets/Images/media/partners/period-pmdd.jpg";
import s2c from "../../Assets/Images/media/partners/s2c.png";
import schoolofsexuality from "../../Assets/Images/media/partners/Schoolofsexuality.gif";
import WFD from "../../Assets/Images/media/partners/WFD.png";
import youth from "../../Assets/Images/media/partners/youth-logo.png";

// awards
import CLOSEGAP from "../../Assets/Images/media/awards/close_the_gap.jpg";
import COLAB from "../../Assets/Images/media/awards/Colab_logo.png";
import FPA from "../../Assets/Images/media/awards/FPA_india.png";
import GIC from "../../Assets/Images/media/awards/global_innovation_challenge.jpg";
import GYA from "../../Assets/Images/media/awards/global_youths_award.jpg";
import IWA from "../../Assets/Images/media/awards/innovation_winner_logo.png";
import KSA from "../../Assets/Images/media/awards/k_startup_award.jfif";
import SEXTECH_SUMMIT from "../../Assets/Images/media/awards/sextech_summit.jpg";
import DIANA_AWARD from "../../Assets/Images/media/awards/the_diana_award.png";
import UN_WOMEN from "../../Assets/Images/media/awards/UN_women.png";
import UNDP_AWARD from "../../Assets/Images/media/awards/UNDP.png";
import WAF_AWARD from "../../Assets/Images/media/awards/waf_logo.png";
import WORLD_BANK from "../../Assets/Images/media/awards/world_bank_logo.png";
import YOUTH_COLAB from "../../Assets/Images/media/awards/youth_colab.jpg";
import YUNUS_YOUTH from "../../Assets/Images/media/awards/yunus_and_youth.jpg";

// import finalist from "../../Assets/Images/media/awards/finalist-badge.jpg";
// import fpa from "../../Assets/Images/media/awards/fpa-india.png";
// import innovationWinner from "../../Assets/Images/media/awards/innovation-winner.png";
// import sextech from "../../Assets/Images/media/awards/sextech-summit.jpg";
// import innovation from "../../Assets/Images/media/awards/top-innovation.jpg";
// import waf_logo from "../../Assets/Images/media/awards/waf_logo.png";
// import ynyFellow from "../../Assets/Images/media/awards/ynyFellow.png";

// in media
import PT from "../../Assets/Images/media/inMedia/ThePeopleToday.png";
import LW from "../../Assets/Images/media/inMedia/LadderWorks.jpg";
import TheBastion from "../../Assets/Images/media/inMedia/TheBastion_Logo.png";
import TheDellaHQ from "../../Assets/Images/media/inMedia/TheDellaHQ_Logo.png";
import TheStartUpTales from "../../Assets/Images/media/inMedia/TheStartUpTales_Logo.jpg";
import AnalyticsService from "../../Services/Analytics";
import i18n from "../../Utils/translation.js";
import "./style.css";
import { withTranslation } from "react-i18next";

class Assoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: "04",
      titles: [
        this.props.t("associated_heading_2"),
        this.props.t("associated_heading_1"),
        this.props.t("associated_heading_3"),
        this.props.t("associated_heading_4"),
      ],
    };
    this.associatedWith = [
      {
        source: OPSAGI,
        alt: "Office of the Principal Scientific Advisor to the Government of India",
        link: "https://en.wikipedia.org/wiki/Principal_Scientific_Adviser_to_the_Government_of_India",
      },
      {
        source: Momentum,
        alt: "Momentum",
        link: "http://villgro.org/yashentrepreneursprogram/",
      },
      {
        source: USAID,
        alt: "US AID From the american people",
        link: "https://www.usaid.gov/",
      },
      {
        source: Microsoft,
        alt: "Microsoft for startups",
        link: "https://www.microsoft.com/en-us/startups",
      },
      {
        source: STARTUP,
        alt: "startup india",
        link: "https://seedfund.startupindia.gov.in/",
      },
      {
        source: UNDP,
        alt: "UNDP",
        link: "https://www.undp.org/",
      },
      {
        source: KSGC,
        alt: "K-Startup grand challenge",
        link: "https://www.k-startupgc.org/",
      },
      {
        source: LINC,
        alt: "36inc",
        link: "https://www.facebook.com/36inc/",
      },
      {
        source: UNLEASH,
        alt: "UNLEASH",
        link: "https://unleash.org/",
      },
      // {
      //   source: GIM,
      //   alt: "GIM",
      //   link: "https://www.aicgim.in/",
      // },
      // {
      //   source: WEBHUB,
      //   alt: "WEBHUB",
      //   link: "http://wehub.telangana.gov.in/",
      // },
      // {
      //   source: ASHOKA,
      //   alt: "ASHOKA",
      //   link: "https://www.ashoka.edu.in/",
      // },
    ];
    this.partners = [
      {
        source: amaze,
        alt: "AMAZE",
        link: "https://amaze.org/",
      },
      {
        source: fmm,
        alt: "FMM",
        link: "https",
      },
      {
        source: htu,
        alt: "HTU",
        link: "https",
      },
      {
        source: outspoken,
        alt: "OUTSPOKEN SEXED",
        link: "https://www.outspokeneducation.com/",
      },
      {
        source: period,
        alt: "PERIOD PMMD",
        link: "https://www.periodpmdd.com/",
      },
      {
        source: s2c,
        alt: "S2C",
        link: "https://safe2choose.org/",
      },
      {
        source: schoolofsexuality,
        alt: "School of sexuality",
        link: "https://schoolofsexed.org/",
      },
      {
        source: WFD,
        alt: "WFD",
        link: "https",
      },
      {
        source: youth,
        alt: "youth",
        link: "https",
      },
    ];

    this.awards = [
      {
        source: CLOSEGAP,
        alt: "CLOSE THE GAP",
        link: "https://www.closingthegap.gov.au/",
      },
      {
        source: COLAB,
        alt: "YOUTH COLAB",
        link: "https://www.youthcolab.org/",
      },
      {
        source: FPA,
        alt: "FPA India",
        link: "https://fpaindia.org/",
      },
      {
        source: GIC,
        alt: "GIC",
        link: "https://socialshifters.innovationchallenge.com/skild2/socialshifters/loginPage.action",
      },
      {
        source: GYA,
        alt: "GYA",
        link: "",
      },
      {
        source: IWA,
        alt: "IWA",
        link: "",
      },
      {
        source: KSA,
        alt: "KSA",
        link: "",
      },
      {
        source: SEXTECH_SUMMIT,
        alt: "SEXTECH SUMMIT",
        link: "",
      },
      {
        source: DIANA_AWARD,
        alt: "DIANA AWARD",
        link: "https://diana-award.org.uk/pages/roh-2022-a-c/",
      },
      {
        source: UN_WOMEN,
        alt: "UN WOMEN",
        link: "https://www.unwomen.org/en",
      },
      {
        source: UNDP_AWARD,
        alt: "UNDP_AWARD",
        link: "https://www.undp.org/",
      },
      {
        source: WAF_AWARD,
        alt: "WAF_AWARD",
        link: "https://drive.google.com/file/d/1SrlKMpWs9hCfcGoW4VoZdqKLejJy4eZ4/view",
      },
      {
        source: WORLD_BANK,
        alt: "WORLD_BANK",
        link: "https://drive.google.com/file/d/1SrlKMpWs9hCfcGoW4VoZdqKLejJy4eZ4/view",
      },
      {
        source: YOUTH_COLAB,
        alt: "YOUTH_COLAB",
        link: "https://www.youthcolab.org/",
      },
      {
        source: YUNUS_YOUTH,
        alt: "YUNUS_YOUTH",
        link: "https://yunusandyouth.com/project/priyal-agrawal/",
      },
      // {
      //   source: accelerator,
      //   alt: "Action Accelerator",
      //   link: "",
      // },
      // {
      //   source: finalist,
      //   alt: "finalist Badge",
      //   link: "",
      // },
      // {
      //   source: fpa,
      //   alt: "fpa",
      //   link: "",
      // },
      // {
      //   source: innovationWinner,
      //   alt: "Innovation Winner",
      //   link: "",
      // },
      // {
      //   source: sextech,
      //   alt: "Accelerator",
      //   link: "",
      // },
      // {
      //   source: innovation,
      //   alt: "Top Innovation",
      //   link: "",
      // },
      // {
      //   source: waf_logo,
      //   alt: "women ambassador forum",
      //   link: "",
      // },
      // {
      //   source: ynyFellow,
      //   alt: "ynyFellow",
      //   link: "",
      // },
    ];
  }
  handleGAEvent = async (link) => {
    await AnalyticsService.gaEvent("Home-media", `outbound link`, `${link}`, 1);
    window.open(link);
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.t !== this.props.t) {
      this.setState({
        titles: [
          this.props.t("associated_heading_2"),
          this.props.t("associated_heading_1"),
          this.props.t("associated_heading_3"),
          this.props.t("associated_heading_4"),
        ],
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Carousel className="min-vh-100" interval={4000}>
          {/* <Carousel className="vh-100" wrap="true"> */}
          <Carousel.Item>
            <div className="assoc-associated-with">
              <div className="assoc-trans-head">{this.state.count}</div>
              <h1>{i18n.t("associated_heading_1")}</h1>
              <Row
                style={{ margin: "120px 20px" }}
                className="assoc-carousel-main-1 justify-content-center text-center carousel-item-slide"
              >
                {this.associatedWith.length > 0 &&
                  this.associatedWith.map((x, i) => (
                    <React.Fragment key={i}>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="d-flex align-items-center"
                      >
                        <img
                          src={x.source}
                          alt={x.alt}
                          style={{
                            width: "120px",
                            height: "80px",
                            objectFit: "contain",
                            marginBottom: 16,
                          }}
                          className="cursorOnHover"
                          onClick={() => this.handleGAEvent(x.link)}
                        />
                      </Col>
                    </React.Fragment>
                  ))}
              </Row>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="assoc-associated-with">
              <div className="assoc-trans-head">{this.state.count}</div>
              <h1>{i18n.t("associated_heading_2")}</h1>
              <Row
                style={{ margin: "104px 20px" }}
                className="assoc-carousel-main-2 justify-content-center text-center carousel-item-slide"
              >
                {this.partners.length > 0 &&
                  this.partners.map((x, i) => (
                    <React.Fragment key={i}>
                      <Col
                        xs={12}
                        md={3}
                        lg={2}
                        className="d-flex align-items-center"
                      >
                        <img
                          src={x.source}
                          style={{
                            maxWidth: "96px",
                            marginBottom: 16,
                          }}
                          alt={x.alt}
                          className="cursorOnHover"
                          onClick={() => this.handleGAEvent(x.link)}
                        />
                      </Col>
                      {(i + 1) % 3 === 0 && <div className="w-100"></div>}
                    </React.Fragment>
                  ))}
              </Row>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="assoc-associated-with">
              <div className="assoc-trans-head">{this.state.count}</div>
              <h1>{i18n.t("associated_heading_3")}</h1>
              <Row
                style={{ margin: "90px 0" }}
                className="assoc-carousel-main-3 justify-content-center text-center carousel-item-slide"
              >
                <Col
                  xs={12}
                  md={3}
                  lg={3}
                  className="d-flex align-items-center"
                >
                  <img
                    src={TheDellaHQ}
                    alt="The Della HQ"
                    className="cursorOnHover"
                    style={{
                      maxWidth: "200px",
                      width: "70%",
                      padding: "30px 0px",
                    }}
                    onClick={() =>
                      this.handleGAEvent(
                        "https://thedellahq.com/femalefounders/standwespeak-changing-sex-education-in-india/"
                      )
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  md={3}
                  lg={3}
                  className="d-flex align-items-center"
                >
                  <img
                    src={TheBastion}
                    alt="The Bastion"
                    className="cursorOnHover"
                    style={{
                      maxWidth: "130px",
                      width: "70%",
                      padding: "30px 0px",
                    }}
                    onClick={() =>
                      this.handleGAEvent(
                        "https://thebastion.co.in/covid-19/sex-education-the-missing-piece-in-the-sexual-health-and-literacy-puzzle/"
                      )
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  md={3}
                  lg={3}
                  className="d-flex align-items-center"
                >
                  <img
                    src={LW}
                    alt="Ladderworks"
                    className="cursorOnHover"
                    style={{
                      maxWidth: "150px",
                      width: "70%",
                      padding: "30px 0px",
                    }}
                    onClick={() =>
                      this.handleGAEvent(
                        "https://www-nasdaq-com.cdn.ampproject.org/c/s/www.nasdaq.com/articles/priyal-agrawal%3A-building-a-21st-century-sexual-health-ecosystem-in-india-2020-10-21?amp"
                      )
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={4}
                  className="d-flex align-items-center"
                >
                  <img
                    src={PT}
                    alt="The People Today"
                    style={{
                      maxWidth: "250px",
                      width: "70%",
                      padding: "30px 0px",
                    }}
                    className="cursorOnHover"
                    onClick={() =>
                      this.handleGAEvent(
                        "https://thepeopletoday.com/the-p-e-o-p-l-e-weekly-edition-24//"
                      )
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={4}
                  className="d-flex align-items-center"
                >
                  <img
                    src={TheStartUpTales}
                    alt="The StartUp Tales"
                    className="cursorOnHover"
                    style={{
                      maxWidth: "250px",
                      width: "70%",
                      padding: "30px 0px",
                    }}
                    onClick={() =>
                      this.handleGAEvent("http://thestartuptales.in/features/")
                    }
                  />
                </Col>
              </Row>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="assoc-associated-with">
              <div className="assoc-trans-head">{this.state.count}</div>
              <h1>{i18n.t("associated_heading_4")}</h1>
              <Row
                style={{ margin: "104px 20px" }}
                className="assoc-carousel-main-2 justify-content-center text-center carousel-item-slide"
              >
                {this.awards.length > 0 &&
                  this.awards.map((x, i) => (
                    <React.Fragment key={i}>
                      <Col
                        xs={12}
                        md={3}
                        lg={2}
                        className="d-flex align-items-center"
                      >
                        <img
                          src={x.source}
                          style={{
                            maxWidth: "120px",
                            marginBottom: 16,
                          }}
                          alt={x.alt}
                          // className="cursorOnHover"
                          // onClick={() => this.handleGAEvent(x.link)}
                        />
                      </Col>
                      {(i + 1) % 5 === 0 && <div className="w-100"></div>}
                    </React.Fragment>
                  ))}
              </Row>
            </div>
          </Carousel.Item>
        </Carousel>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Assoc);
