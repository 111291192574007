import React from "react";
import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import AnalyticsService from "../../Services/Analytics";
import "./style.css";

class NewsLetter extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "" };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = async (event) => {
    alert("Thanks for subscribing to our newsletter!");
    var body = this.state;
    await AnalyticsService.gaEvent(
      "Home-newsletter",
      `User Subscribed`,
      `${this.state.email} added to newsletter`,
      1
    );
    fetch(`${process.env.REACT_APP_BACKEND_URL}/app/subscribe`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // We convert the React state to JSON and send it as the POST body
      body: JSON.stringify(body),
    }).then(function (response) {
      return response.json();
    });

    event.preventDefault();
  };

  render() {
    const { t } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <Row
          id="reg-newsletter"
          className="justify-content-center contact-news-letter"
          style={{ margin: "0px" }}
        >
          <Col md={12} className="mb-4">
            <h2>{t("subscribe_newsletter")}</h2>
            {/* <hr className="contact-bar" /> */}
          </Col>
          <Col xs={12} md={8} lg={6}>
            <input
              type="email"
              name="email"
              placeholder="johndoe@mail.com"
              className="contact-news-input"
              value={this.state.value}
              required
              onChange={this.handleChange}
            />
            <button type="submit" className="btn button btn-success">
              {t("subscribe_title")}
            </button>
          </Col>
        </Row>
      </form>
    );
  }
}

export default withTranslation()(NewsLetter);
