import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import {
  logInUser,
  setAuthScreen,
  setShowModal,
} from "../../actions/loginActions";
import FacebookButton from "../SocialButtons/facebookLogin";
import GoogleButton from "../SocialButtons/googleButton";
import LinkedInButton from "../SocialButtons/LinkedInButton";
import ToastUtil from "../../Services/ToastUtil";
import "./style.css";

const LoginButton = styled.button`
  padding: 4px;
  width: 100%;
  background: black;
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins";
  border: none;
  box-shadow: 0 3px 5px 2px #9f9d9d;
  margin-top: 10px;

  &:hover {
    background: white;
    color: black;
  }
`;

const LoginInputs = styled.input`
  background: transparent;
  border: none;
  border-bottom: 2px solid #959c9c;
  color: black;
  padding: 0.25em 1em;
  width: 100%;
  font-family: "Poppins";
  font-size: 14px;

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.primary &&
    css`
      border-bottom: 2px solid palevioletred;
      color: palevioletred;
    `};
`;
const LoginHeading = styled.h4`
  text-align: center;
  color: black;
`;

class AuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }
  redirectToSite(link) {
    window.location.href = link;
    window.location.target = "_self";
  }

  validateData(obj) {
    for (var key in obj) {
      if (obj[key] == null || obj[key] === "") return false;
    }
    return true;
  }

  btnClick(e) {
    // login code
    e.preventDefault();
    const formData = this.state;
    if (this.validateData(formData)) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/user/login`, formData)
        .then((response) => {
          // console.log(response.data);//testing..
          //set the same object in global state .. redux
          if (response.status === 200) {
            this.props.dispatch(logInUser(response.data.user));
            // console.log("User", response.data);
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            localStorage.setItem("email", formData.email);
            localStorage.setItem("password", formData.password);
            // console.log('Token:  ' + response.data.accessToken);
            this.props.dispatch(setShowModal(false));
            ToastUtil.popUpMessage("Successfully Logged In!");
          } else {
            ToastUtil.popUpMessage("Kindly re-check your email or password!");
          }
        })
        .catch((error) => {
          console.log(error);
          ToastUtil.popUpMessage("Kindly re-check your email or password!");
        });
    } else {
      ToastUtil.popUpMessage("Kindly fill all field!");
    }
  }
  render() {
    return (
      <Row>
        <Col md={12} className="px-5 mt-1">
          <LoginHeading>Sign In</LoginHeading>
          <LoginInputs
            placeholder="Enter Email"
            onChange={(e) => {
              this.setState({ email: e.target.value });
            }}
            value={this.state.email}
            required
          />
          <LoginInputs
            type="password"
            placeholder="Enter Password"
            onChange={(e) => {
              this.setState({ password: e.target.value });
            }}
            value={this.state.password}
            required
          />
          <LoginButton onClick={(event) => this.btnClick(event)}>
            Log In
          </LoginButton>
          <LoginButton
            onClick={() => this.props.dispatch(setAuthScreen("register"))}
          >
            Register
          </LoginButton>
        </Col>
        <Col md={5}>
          <hr />
        </Col>
        <Col md={2} className="py-1">
          <strong>OR</strong>
        </Col>
        <Col md={5}>
          <hr />
        </Col>
        <Col md={12}>
          <p className="text-center my-0 font-weight-bold">Register As</p>
          <GoogleButton /> | <FacebookButton /> | <LinkedInButton />
          {/* <GoogleButton /> | <FacebookButton /> | <TwitterButton /> | <LinkedInButton /> */}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.LoginReducer,
  };
}

export default connect(mapStateToProps, null)(AuthContainer);
// export default AuthContainer;
