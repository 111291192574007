/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { RiSearch2Line } from 'react-icons/ri'
import { IconContext } from 'react-icons'
import { useTranslation } from 'react-i18next'
import '../SearchBar/style.css'

export default ({ searchTerm, placeholder, removeIcon = false, cssStyle }) => {
  let txtElements
  let wordss
  let txt
  let wordIndex
  let waits
  let isDeleting

  const { t } = useTranslation()

  // const [dataWords, setDataWords] = useState();

  const [SearchInput, setSearchInput] = useState('')

  const typeWriter = function (txtElement, words, wait = 3000) {
    txtElements = txtElement
    wordss = words
    txt = ''
    wordIndex = 0
    waits = parseInt(wait, 10)
    type()
    isDeleting = false
  }

  const type = function () {
    //current index of word
    const current = wordIndex % wordss.length
    //Get full text of current word
    const fullTxt = wordss[current]

    // console.log(fullTxt);

    //check if deleting
    if (isDeleting) {
      //Remove char
      txt = fullTxt.substring(0, txt.length - 1)
    } else {
      //Add char
      txt = fullTxt.substring(0, txt.length + 1)
    }

    //Insert txt into element
    // txtElements.innerHTML = `<span className="txt"> ${txt}</span>`
    // txtElements.placeholder = `Ask me about ${txt}`;
    txtElements.innerHTML = `${txt}`

    //Initial type speed
    let typeSpeed = 50

    if (isDeleting) {
      typeSpeed /= 2
    }

    //if word is complete
    if (!isDeleting && txt === fullTxt) {
      //Make pause at end
      typeSpeed = waits
      //Set delete to true
      isDeleting = true
    } else if (isDeleting && txt === '') {
      isDeleting = false
      //Move to next word
      wordIndex++
      //Pause before start typing
      typeSpeed = 500
    }

    window.expertTimeOut = setTimeout(() => type(), typeSpeed)
  }

  useEffect(() => {
    if (window.expertTimeOut) clearTimeout(window.expertTimeOut)
    const txtElement = document.querySelector('.txt-type-expert')
    const words = t('search_experts_words', { returnObjects: true })
    const wait = txtElement.getAttribute('data-wait-second')
    new typeWriter(txtElement, words, wait)
  }, [t])

  return (
    <React.Fragment>
      <label
        style={{ position: 'relative', borderBottom: '3px solid #473063' }}
      >
        <input
          placeholder={''}
          className="faq-search-input"
          value={SearchInput}
          style={{ ...cssStyle, paddingLeft: '0px', color: 'white' }}
          onChange={(e) => {
            setSearchInput(e.target.value)
            searchTerm(e.target.value.toLowerCase().trim())
          }}
        />
        {!removeIcon && (
          <IconContext.Provider value={{ size: '50px', color: '#7d84c4' }}>
            <RiSearch2Line className="search-icon" />
          </IconContext.Provider>
        )}
        <span
          style={{
            display: SearchInput ? 'none' : 'inherit',
            left: '0%',
            color: 'white',
            fontFamily: 'Poppins',
            fontSize: '64px',
            fontWeight: 300,
            lineHeight: 'normal'
          }}
          className="txt-type txt-type-expert"
          data-wait-second="2000"
        ></span>
      </label>
    </React.Fragment>
  )
}
