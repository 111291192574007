import { Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./style.css";

const Mentor = (props) => {
  let name = props.mentor.name;
  name = name.toLowerCase();
  name = name.split(" ");
  name = name[name.length - 1];

  return (
    <Row>
      <Col xs="12" md="6">
        <img
          src={props.mentor.thumbnail}
          alt={props.mentor.name}
          style={{ width: "100%" }}
        />
      </Col>
      <Col xs="12" md="6">
        <div
          className="row-mentors"
          style={{
            display: "flex",
            marginLeft: "10px",
            flexDirection: "column",
          }}
        >
          <strong>{props.mentor.name}</strong>
          <h6 id={name}>{props.mentor.designation}</h6>
          <p>{props.mentor.description}</p>
        </div>
      </Col>
    </Row>
  );
};

export default (props) => {
  const [mentor, setMentor] = useState([]);

  useEffect(() => {
    const getMentor = async () => {
      const response = await Axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/mentor/find`
      );
      setMentor(response.data);
    };
    getMentor();
    // TeamService.getMentors(setmentor);
  }, []);

  return (
    <React.Fragment>
      <div>
        <div>
          <div className="heading-container">
            <h1 className="flx">Mentors</h1>
            <h3>Meet Our Mentors</h3>
          </div>
          <p className="tag-line">
            A mentor is someone who allows you to see the hope inside yourself -
            Oprah Winfrey
          </p>
        </div>
        <div className="container-fluid ">
          {/* <div className="row" style={{ marginLeft: "5%", marginRight: "5%" }}> */}
          <div
            className="row"
            style={{
              marginTop: "80px",
              marginLeft: "6%",
              marginRight: "6%",
              marginBottom: "12%",
            }}
          >
            {mentor &&
              mentor.map((mentor, i) => {
                return (
                  <Col key={i} xs={12} lg={6} style={{ marginBottom: "60px" }}>
                    <Mentor className="color" mentor={mentor} {...props} />
                  </Col>
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
