export const USER_AGE_TYPES = {
  age_below_13: "Below 13",
  age_13_to_15: "13-15",
  age_16_to_18: "16-18",
  age_19_to_21: "19-21",
  age_22_to_25: "22-25",
  age_26_to_30: "26-30",
  age_31_to_35: "31-35",
  age_36_to_40: "36-40",
  age_41_to_45: "41-45",
  age_above_45: "Above 45",
};

export const SCHOOLS_USER_AGE_TYPES = {
  age_13_to_15: "13-15",
  age_16_to_18: "16-18",
};

export const CHATBOT_TOPICS = {
  ABORTION_HEALTH_SERVICES: "Abortion & Health Services",
  BODIES_AND_DEVELOPMENT: "Bodies and development",
  GENDER_AND_SEXUALITY: "Gender and Sexuality", // understanding gender
  LIFE_SKILLS_DEVELOPMENT: "Life-skills development",
  MASTURBATION_AND_PLEASURE: "Masturbation and Pleasure",
  MENSTRUATION: "Menstruation",
  PARENT_GUIDE: "Parent's guide",
  STD_PREGNANCY_CONTRACEPTION: "STDs, Pregnancy and Contraception",
  SEX_CONSENT_RELATIONSHIP: "Sex, Consent and Relationships",
  SYMPTOMS: "Symptoms",
  UNDERSTANDING_EMOTIONS: "Understanding Emotions",
  VIOLENCE_AND_SAFETY: "Violence and Safety",
};

export const ENTERPRISE_TYPES = {
  SWS: "SWS",
  MANSURI: "Mansuri",
  SCHOOLS: "Schools",
};
