/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import AnalyticsService from "../../Services/Analytics";
import "./card-style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ProductList = React.memo(
  ({ data = [], category, buttonTitle }) => {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 380,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const [itemList, setItemList] = useState([]);
    useEffect(() => {
      if (data.length > 0) {
        setItemList(data);
      }
    }, []);

    return (
      <div>
        <Slider className="bot-product-list" {...settings}>
          {itemList.length > 0 &&
            itemList.map((product, i) => (
              <div
                key={i}
                className="bot-product-item"
                onClick={async () => {
                  await AnalyticsService.gaEvent(
                    `Chatbot-service ${category}`,
                    `outbound Link`,
                    `${product[category + "_url"]}`,
                    1
                  );
                  if (category === "consults") {
                    window.open('https://forms.gle/B9JHN2rvd1xdnp1n6', "_blank")
                  } else {
                    window.open(product[category + "_url"], "_blank");
                  }
                }}
              >
                <div
                  className={`bot-product-player ${category === "resources" ? "purple-bg" : ""
                    }`}
                >
                  <div className="bot-product-detail-container">
                    <img
                      src={product[category + "_image"]}
                      alt="product"
                      width="100%"
                      className="bot-product-img"
                    />
                  </div>
                  <div
                    className={`bot-product-title ${category === "resources" ? "text-white" : ""
                      }`}
                  >
                    {product[category + "_title"]?.slice(0, 48)}
                  </div>
                  <div
                    className={`bot-product-sub-title ${category === "resources" ? "text-white" : ""
                      }`}
                  >
                    {product[category + "_subtitle"]?.slice(0, 20)}
                  </div>
                </div>
                <div
                  className="bot-product-button"
                >
                  {buttonTitle}
                </div>
              </div>
            ))}
        </Slider>
      </div>
    );
  }
);
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
