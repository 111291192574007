import React from "react";
import { useTranslation } from "react-i18next";
import Heading from "../Reusable/Heading";
import Footer from "../Footer";
import "./style.css";
import GetInTouch from "./getintouch";
import NewsLetter from "./newsletter";

export default () => {
  const { t } = useTranslation();
  let count = "06";
  let heading = t("get_in_touch_title");
  let title = t("get_in_touch_subtitle");

  return (
    <React.Fragment>
      <div id="contact-us">
        <NewsLetter />
        <div id="contact-mail" className="bg-image4 outer-container">
          <Heading count={count} heading={heading} title={title} />
          <GetInTouch />
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};
