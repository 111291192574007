/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import LinkCard from "../LinkCard/Index";

import "./Style.css";

const ResourceCategory = ({ ctg_name, cards }) => {
  const [translatedDistance, setTranslatedDistance] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [overflowWidth, setOverflowWidth] = useState(1);
  const [nextArrowActive, setNextArrowActive] = useState(true);
  const [prevArrowActive, setPrevArrowActive] = useState(false);

  const inner_Cards_container_Ref = useRef();
  const CardRef = useRef();

  //get card-width
  useEffect(() => {
    if (CardRef.current) {
      setCardWidth(CardRef.current.clientWidth);
    }
  }, [CardRef.current]);

  //get Overflow-Width of cards-container
  useEffect(() => {
    if (inner_Cards_container_Ref.current) {
      const { scrollWidth, clientWidth } = inner_Cards_container_Ref.current;
      setOverflowWidth(scrollWidth - clientWidth);
    }
  }, [inner_Cards_container_Ref.current]);

  //translate cards-container (right/left) according to (translatedDistance)
  useEffect(() => {
    inner_Cards_container_Ref.current.style.transform = `translateX(${translatedDistance}px)`;

    if (translatedDistance < 0) {
      setPrevArrowActive(true);
    } else {
      setPrevArrowActive(false);
    }

    if (Math.abs(translatedDistance) < overflowWidth) {
      setNextArrowActive(true);
    } else {
      setNextArrowActive(false);
    }
  }, [translatedDistance]);

  //set handleNext function
  const handleNext = () => {
    if (Math.abs(translatedDistance) < overflowWidth) {
      if (overflowWidth - Math.abs(translatedDistance) < cardWidth) {
        setTranslatedDistance((value) => {
          return value - (overflowWidth - Math.abs(translatedDistance));
        });
      } else {
        setTranslatedDistance((value) => value - cardWidth - 10);
      }
    }
  };

  //set handlePrev function
  const handlePrev = () => {
    if (translatedDistance < 0) {
      if (Math.abs(translatedDistance) > cardWidth) {
        setTranslatedDistance((value) => value + cardWidth + 10);
      } else {
        setTranslatedDistance((value) => value + Math.abs(translatedDistance));
      }
    }
  };

  return (
    <div className="ctegory_container">
      <h2 className="ctegry_name">{ctg_name}</h2>
      <div className="inner_container">
        <div
          className={`prev ${prevArrowActive && "active"}`}
          onClick={handlePrev}
        ></div>

        <div className="outer_cards_container">
          <div
            className="inner_Cards_container"
            ref={inner_Cards_container_Ref}
          >
            {cards.map((card, index) =>
              index === 0 ? (
                <div ref={CardRef}>
                  <LinkCard
                    key={index}
                    bgSource={card.bgSource}
                    title={card.title}
                    subtitle={card.subtitle}
                    link={card.link}
                  />
                </div>
              ) : (
                <div>
                  <LinkCard
                    key={index}
                    bgSource={card.bgSource}
                    title={card.title}
                    subtitle={card.subtitle}
                    link={card.link}
                  />
                </div>
              )
            )}
          </div>
        </div>

        <div
          className={`next ${nextArrowActive && "active"}`}
          onClick={handleNext}
        ></div>
      </div>
    </div>
  );
};

export default ResourceCategory;
