import React from "react";
import "./Style.css";

const LinkCard = ({ bgSource, title, subtitle, link }) => {
  return (
    <div className="card_container">
      <div className="img_box">
        <img src={bgSource} alt="bgImg" />
      </div>
      <div className="info">
        <h6 className="title">{title}</h6>
        <h4 className="subtitle">{subtitle}</h4>
        <button className="link_btn">
          <a href={link} target="_blank" rel="noopener noreferrer">
            read more
          </a>
        </button>
      </div>
    </div>
  );
};

export default LinkCard;
