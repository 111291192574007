import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import AOS from 'aos'

//importing components
// import FrontQuote from "../../Components/FrontQuote";
// import AboutUs from "../../Components/AboutUS";
// import Services from "../../Components/Services";
// import Programs from "../../Components/Programs";
// import Csse from "../../Components/Csse";
// import Testimonials from "../../Components/Testimonials";
// import WhyUs from "../../Components/WhyUs";
import Hiring from '../../Components/Hiring'
import Contact from '../../Components/ContactUs'
import Assoc from '../../Components/Assoc'
import SearchBar from '../../Components/SearchBar'
import { ChatbotBanner } from '../../Components/ChatbotBanner'
// import ExpertSection from '../../Components/ExpertSection'
import Disclaimer from '../../Components/Disclaimer'

export default (props) => {
  const [, setSearchTerm] = useState('')

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    AOS.init({
      duration: 1200,
      once: true,
      easing: 'ease-in-out-back',
      anchorPlacement: 'center-top'
    })
  }, [])

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          StandWeSpeak | Mae | Sexual and Reproductive Health Solutions
        </title>
        <meta
          name="title"
          content="StandWeSpeak | Mae | Sexual and Reproductive Health Solutions"
        />
        <meta
          name="description"
          content="Mae - Your 24x7, AI-driven sexual and reproductive health expert. Let's talk about Relationships, Pregnancy, Contraception, Abortion, Abuse, Sexuality and more."
        />
        <meta
          name="keywords"
          content="Abortion, Contraception, Pregnancy, Sexual health, Sexual Wellness, Menstruation, Abuse, Domestic Violence, Puberty, Pleasure, Gender, LGBTQ+, Sexuality, Consent, Relationships, Breakup, Violence, iPill, Chatbot, Periods, Porn, STDs, HIV, Miscarriage, Bullying, Sexologist, Women's doctor, Gynecologist."
        />
      </Helmet>
      <br />
      <SearchBar searchTerm={setSearchTerm} />
      <ChatbotBanner />
      <br />
      <Disclaimer />
      <Assoc />
      <Hiring {...props} />
      <Contact />

      {/* <FrontQuote />
      <AboutUs />
      <Services />
      <Programs />
      <Csse />
      <Testimonials />
      <WhyUs />
      <Hiring {...props} />
      <Assoc />
      <Contact /> */}
      {/* <Modal
        show={mshow}
        onHide={() => setMshow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Join Socials Club Now
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          We are seeking proven leaders, aware and woke students to build a
          strong community of radical thinkers and social changemakers on select
          college campuses. This is more than hosting events and handing out
          free swag — our members lead the StandWeSpeak community. It's about
          embodying StandWeSpeak and promoting social and gender equality on
          your campus and beyond.
          <br />
          <br />
          <center>
            <Nav.Link href="/SocialsClub">
              <Button variant="outline-primary">Know More...</Button>
            </Nav.Link>
          </center>
        </Modal.Body>
      </Modal> */}
    </div>
  )
}
