import React from "react";
import { useTranslation } from "react-i18next";
import Heading from "../Reusable/Heading";
import "./style.css";

const Disclaimer = () => {
  const { t } = useTranslation();

  let count = "03";
  let heading = t("disclaimer_heading");
  let title = "";

  return (
    <div className="disclaimerBody">
      <Heading
        count={count}
        heading={heading}
        title={title}
        headingColor={"#000"}
      />
      <br />
      <p>{t("disclaimer_body_1")}</p>
      <p>{t("disclaimer_body_2")}</p>
    </div>
  );
};

export default Disclaimer;
