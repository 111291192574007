import React from "react";
import { Helmet } from "react-helmet";
import ResourceCategory from "../../Components/Resource_Category/Index";
import { categories } from "./Data";
import "./Style.css";

const Resources = () => {
  return (
    <div className="page_container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>StandWeSpeak | Best Resources</title>
        <meta name="title" content="StandWeSpeak | Best Resources" />
        <meta
          name="description"
          content="Find the best curated resources for all things Sex-Ed"
        />
        <meta
          name="keywords"
          content="Resources, Abortion, Contraception, Pregnancy, Sexual health, Sexual Wellness, Menstruation, Abuse, Domestic Violence, Puberty, Pleasure, Gender, LGBTQ+, Sexuality, Consent, Relationships, Breakup, Violence, iPill, Chatbot, Periods, Porn, STDs, HIV, Miscarriage, Bullying, Sexologist, Women's doctor, Gynecologist."
        />
      </Helmet>
      <h1 className="page_title">our resources</h1>
      <div className="categories_Container">
        {categories.map((category, index) => (
          <ResourceCategory
            key={index}
            ctg_name={category.name}
            cards={category.cards}
          />
        ))}
      </div>
    </div>
  );
};

export default Resources;
