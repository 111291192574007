import React, { useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import EXPERT_IMAGE from '../../Assets/Images/home/expert_image.png'
import SearchBar from '../ExpertSearchBar'
import AnalyticsService from '../../Services/Analytics'
import '../../Styles/main.css'
import './style.css'

export const ExpertSection = () => {
  const [cssStyle] = useState('')
  const [, setSearchTerm] = useState('')

  return (
    <div className="chatbot-introduce">
      <div
        className="chatbot-introduce-banner"
        style={{ background: '#67548c', color: 'white' }}
      >
        <div className="expert-banner-image">
          <img
            src={EXPERT_IMAGE}
            style={{ width: '320px' }}
            alt="Mae the chatbot"
          />
        </div>
        <div className="expert-banner-content">
          <div className="chatbot-banner-content-chat">
            <br />
            <div className="search-bar-container">
              <SearchBar
                searchTerm={setSearchTerm}
                cssStyle={cssStyle}
                removeIcon={true}
              />
            </div>
            <div className="sws-body mb-32 color-purple-500">
              What do you want to talk about?
            </div>
            <div className="sws-body mb-24 color-purple-300">
              <ul style={{ marginLeft: 16 }}>
                <li>Tell us the problem you want to talk about.</li>
                <li>
                  We will suggest you our best 3 recommendations based on our
                </li>
              </ul>
            </div>
            <div className="sws-body mb-24 color-purple-300">
              Your well-being matters, and discussing your concerns with
              professionals who specialize in this field can provide you with
              invaluable guidance, support, and understanding.
            </div>
            <div
              className="sws-button"
              onClick={async (e) => {
                e.preventDefault()
                await AnalyticsService.gaEvent(
                  'expert-google-form-link',
                  'outbound link',
                  'go to experts form',
                  1
                )
                window.open('https://forms.gle/B9JHN2rvd1xdnp1n6', '_blank')
              }}
            >
              Find experts <FaChevronRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
