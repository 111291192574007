import React, { useEffect, useState } from "react";
import Axios from "axios";
import authAxios from "../Reusable/AuthAxios";
import UserImage from "../../Assets/Images/UserImage.png";
import "./style.css";

const PostContainer = (props) => {
  return (
    <div className="forum-post">
      <div>
        <img className="user-image" src={UserImage} alt="user" />
      </div>
      <div className="flx">
        <div>
          {props.comment ? <strong>RE: {props.topic}</strong>
            :
            <strong>{props.topic}</strong>
          }
          <p>
            By {props.postedBy} - {props.createdAt}
          </p>
        </div>
        <div>
          <p>{props.question}</p>
        </div>
        <div
          style={{ width: "100%", textAlign: "right", paddingRight: "25px" }}
        >
          <a href="#home">Reply</a>
          <span> | </span>
          <a href="#home">Edit</a>
          <span> | </span>
          <a href="#home">Delete</a>
        </div>
      </div>
    </div>
  );
};

export default (props) => {
  const [Post, setPost] = useState({});
  const [Comment, setComment] = useState("");


  useEffect(() => {
    if(props.match.params.id){
      
    const getForumPost = async () => {
      const post = await Axios.get(
        `https://backend.standwespeak.com/forum/single/${props.match.params.id}`
      );
      setPost(post.data);
      // console.log(post.data);
    };
    getForumPost();
  }
  }, [props.match.params.id]);

  const handleSubmitComment = (e) => {
    e.preventDefault();

    const data = {
      postId: Post._id,
      comment: Comment,
      postedBy: localStorage.getItem("username"),
    };
    authAxios.post(`https://backend.standwespeak.com/forum/comment`, data)
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
        console.log("some error occured!", response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <PostContainer
        topic={Post.topic}
        postedBy={Post.postedBy}
        createdAt={Post.createdAt}
        question={Post.question}
      />
      <br />
      {Post.comments
        ? Post.comments.map((comment, i) => {
          return (
            <div key={i} style={{ paddingLeft: "60px" }}>
              <PostContainer
                topic={Post.topic}
                postedBy={comment.postedBy}
                createdAt={comment.createdAt}
                question={comment.comment}
                comment={true}
              />
            </div>
          );
        })
        : null}
      <br />
      <div className="add-comment">
        <h5>Add Comment</h5>
        <textarea
          onChange={(e) => setComment(e.target.value)}
          value={Comment}
        />
        <button type="submit" onClick={handleSubmitComment}>
          submit
        </button>
      </div>
    </React.Fragment>
  );
};
