import React, { Fragment, useEffect } from "react";
import "./style.css";

function Shipping(props) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Fragment>
      <div class="compliance-page">
        <div class="header-container">
          <img
            src="https://cdn.razorpay.com/logos/HGfLUaCvt5zCmN_original.png"
            style={{ height: "64px", width: "64px" }}
            alt="razor-pay"
          />
          <p class="header-text">StandWeSpeak</p>
        </div>
        <div class="compliance-content">
          <div class="content-container">
            <p class="content-head">Shipping &amp; Delivery Policy</p>
            <div class="content-separator"></div>
            <p class="updated-date">Last updated on Oct 17th 2022</p>

            <p class="content-text">Shipping is not applicable for business.</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default Shipping;
