import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.css";

function TermsConditions(props) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>StandWeSpeak | Terms and Conditions</title>
        <meta
          name="description"
          content="Learn more about our terms and condition. "
        />
        <meta
          name="keywords"
          content="Terms and conditions, Abortion, Contraception, Pregnancy, Sexual health, Sexual Wellness, Menstruation, Abuse, Domestic Violence, Puberty, Pleasure, Gender, LGBTQ+, Sexuality, Consent, Relationships, Breakup, Violence, iPill, Chatbot, Periods, Porn, STDs, HIV, Miscarriage, Bullying, Sexologist, Women's doctor, Gynecologist."
        />
      </Helmet>
      <div className="wrapper serviceBody">
        <div className="page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Terms & Conditions- StandWeSpeak</h1>
                <p>
                  Welcome to StandWeSpeak/Mae, the online service of
                  StandWeSpeak (hereinafter referred to as "StandWeSpeak/Mae",
                  or "We", or "Us" or "Our" or “Mae”). The domain name
                  www.standwespeak.com (hereinafter referred to as "Website") is
                  owned and managed by StandWeSpeak, a partnership firm,
                  incorporated and existing under the laws of India and having
                  its registered office at 304, Amaltas, D.M.Vatika, Shankar
                  Nagar, Raipur, Chhattisgarh, India-492001). This Terms of
                  Service applies to all visitors, data subject, and others who
                  access the Service ("User(s)" or “You/r”). This page explains
                  the terms by which You may use Our Website, other websites
                  maintained by Us, or Our web browser based application either
                  on the StandWeSpeak/Mae website or integrated within any other
                  website and/or web browser based AI chatbot service, digital
                  premium service, and/or the StandWeSpeak/Mae
                  Expert/Consultancy Service provided via StandWeSpeak/Mae
                  (collectively referred to as the "Service(s)”). By accessing
                  or using the Service, You signify that You have read,
                  understood, and agree to be bound by this terms of service
                  ("Terms and Condition") and to the collection and use of Your
                  Information as set forth in the Privacy Policy, and terms of
                  any other policies of StandWeSpeak/Mae as may be applicable to
                  You whether or not You are a registered User of Our Service.
                  This Terms and Condition, Privacy Policy and any other terms
                  and conditions as may be applicable shall hereinafter
                  collectively be referred to as “Agreement” or “Contract”. For
                  further Information regarding the use of Your data by
                  StandWeSpeak/Mae, please read Our Privacy Policy.
                </p>
                <h2>Important Notice:</h2>
                <ul>
                  <li>
                    If You are having suicidal thoughts or planning to act on
                    suicidal thoughts, or if You feel that You may be a danger
                    to Yourself or to others, or if You otherwise have any
                    medical or mental health emergency or severe mental health
                    condition, or if You are in a crisis or trauma or abuse,
                    please discontinue use of the Service immediately and call
                    the relevant emergency number in Your country and notify the
                    police or emergency medical Services.
                  </li>

                  <li>
                    If You are less than 18 years of age, please read through
                    the StandWeSpeak Privacy Policy and the StandWeSpeak Terms
                    of Service with Your parent or legal guardian or check with
                    Your Institution to understand eligibility before use. If
                    you are a minor in your jurisdiction, your registration and
                    use of our Services must be with the supervision of an
                    adult. StandWeSpeak/Mae is not to be used by children under
                    13 years.
                  </li>

                  <li>
                    You must have attained the age of majority to be able to use
                    and access our Services. As a parent or legal guardian,
                    please do not allow your minors under your care to submit
                    Personal Information to us. In the event that such Personal
                    Information of minor is disclosed to us, you hereby consent
                    to the processing of the minor’s Personal Information and
                    accept and agree to be bound by this Privacy Policy and take
                    responsibility for his or her actions.
                  </li>

                  <li>
                    Your interaction with the StandWeSpeak/Mae Bot is with an AI
                    chatbot and not a human. The Bot is restricted in the means
                    of response, and the intended use is for providing
                    evidence-based tools and techniques to manage emotions and
                    encourage sexual health and mental well-being in a self-help
                    context. It is not intended for providing diagnosis,
                    treatment or cure of a condition or disease. The Bot cannot
                    and will not offer advice on issues it does not recognize.
                  </li>

                  <li>
                    The StandWeSpeak/Mae Expert/Consultancy Service will use
                    online sessions mode to help You work towards Your personal
                    well-being goals. The underlying principle of the
                    Expert/Consultancy Service is that You have the knowledge
                    and capacity to make desired changes in Your life. The role
                    that an Expert/Consultant will play is to support You in
                    finding Your own way, help You tap into Your own strengths
                    and abilities, so that You can identify and use resources
                    around You to fill any gaps.
                  </li>

                  <li>
                    StandWeSpeak/Mae Expert/Consultancy services are not
                    intended to provide a diagnosis, prognosis, treatment or
                    cure for a disease/condition/disorder or disability or
                    provide any type of state-regulated mental or sexual health
                    services in Your country of residence. It is an enabling and
                    empowering mode of support, rather than treatment of illness
                    or a health condition.
                  </li>

                  <li>
                    By using the StandWeSpeak/Mae Expert/Consultant Services,
                    You understand and agree that the Expert assigned to work
                    with You will be located remotely and may not be located in
                    Your country or state of residence.
                  </li>

                  <li>
                    For safety and security reasons, We strongly recommend that
                    You keep Your conversations with StandWeSpeak/Mae private.{" "}
                  </li>

                  <li>
                    By using the Service, You understand and agree that Your
                    submitted Data will be transferred, stored and processed in
                    Our secure servers.
                  </li>
                </ul>
                <hr />
                <h2>What is Mae?</h2>
                <p>
                  Mae (developed and powered by StandWeSpeak) is a virtual AI
                  chatbot (“Bot”, “Mae” or “StandWeSpeak/Mae Bot”) that You can
                  chat with, including upon Your choice, the ability to book a
                  teleconsultation session with highly trained sexual and
                  reproductive health professional (“Consultant” or “Expert”),
                  and through a conversational interface get access to tools,
                  resources and techniques to manage your sexual health. The
                  StandWeSpeak/Mae is primarily available as a web browser based
                  system either on the StandWeSpeak/Mae website or integrated
                  within an Institution website. Your Interaction with the Bot
                  is with an artificial intelligence chatbot and not a human.
                  The Bot is restricted in the means of response, and the
                  intended usage of StandWeSpeak/Mae Website is for providing
                  evidence-based tools and techniques to manage sexual and
                  reproductive health as an early intervention tool in a
                  self-help context. You make the choice of using the Bot, based
                  on Your own estimate of need, and agree that this is only
                  suitable for basic self-help. This is not intended to be a
                  replacement for face-to-face expert consultancy, therapy or to
                  provide a diagnosis, prognosis, treatment or cure for a
                  disease/condition/disorder or disability. The Bot cannot and
                  will not offer advice on issues it does not recognize. Using
                  the StandWeSpeak/MaeWebsite, You can track and manage Your
                  sexual health, and learn context-sensitive evidence-based
                  techniques that can help You feel better. StandWeSpeak/Mae
                  Website and Service is not intended for use in crisis such as
                  abuse or complex or severe health conditions that causes for
                  example; ideation of suicide, harm to self and others, or for
                  any medical emergencies. StandWeSpeak/Mae Website and Service
                  cannot and will not offer medical or clinical advice. It can
                  only suggest that the user seeks advanced (medical) help.
                </p>
                <hr />
                <h2>Who can Use the Service?</h2>
                <p>
                  You may Use the Service only if You are a natural/legal
                  person, agree to this Agreement and form a binding contract
                  with StandWeSpeak/Mae, and only in compliance with all
                  applicable local, state, national, and international laws,
                  rules and regulations. If You are between 13 and 18 years of
                  age, please read through this StandWeSpeak Privacy Policy and
                  the StandWeSpeak Terms of Service with Your parent or legal
                  guardian, and in such a case the Agreement shall be deemed to
                  be a contract between StandWeSpeak and Your legal guardian or
                  parent and to the extent permissible under applicable laws,
                  enforceable against You. Anyone under 13 is strictly
                  prohibited from creating an account and/or Using the Service.
                  <br />
                  If Your Institution specifies a different age restriction,
                  such as at least 18 and above, as a condition of using this
                  Service, that restriction shall apply rather than the one
                  above.
                  <br />
                  If You use the StandWeSpeak/Mae Expert or Consultancy Service,
                  You will be asked to provide a Parental or Legal Guardian
                  consent if You reveal Your age to be between 13 and 18 years.
                  You will be required to inform Your parents or legal guardian
                  and have them send Us an email consent to
                  info@standwespeak.com, as directed by Your StandWeSpeak/Mae
                  Expert/Consultant, using the same email ID that was used to
                  subscribe to Our Service. Without receiving parental or legal
                  guardian consent, We will be unable to offer Our full
                  Services. If Your parents or legal guardian contact Us We will
                  collect minimal Personal Information such as Your name, Your
                  parents name, Your parent’s email address and the consent
                  message. We process Your data for our Legitimate Interest to
                  provide You Our StandWeSpeak/ Mae Expert/Consultancy Service.
                  We will not use Your Personal data for any Direct Marketing
                  without Your Consent. We will not sell Your Personal data to
                  any third party.
                </p>
                <h2>Institution or other Consumer users</h2>
                <p>
                  Corporations, schools, universities, hospitals, insurance,
                  government, NGOs, and other organizations (“Institution(s)”)
                  may introduce StandWeSpeak/Mae Website or chatbot to their
                  employees and members. StandWeSpeak/Mae may carry links to
                  material or Websites or Web Pages or Services managed by third
                  parties on which the Institution and StandWeSpeak may have no
                  control, agreement or affiliation. Please refer to
                  documentation including terms and Privacy Policy provided by
                  such third parties before Using Your Institution Version.
                </p>
                <hr />
                <h2>About StandWeSpeak/Mae Expert/Consultancy Service</h2>
                <p>
                  <i>
                    [This section applies only to the sessions with a
                    StandWeSpeak/Mae Expert/Consultant. For some promotional
                    purposes, StandWeSpeak/Mae may provide links to other
                    websites, resources, and/or support, in which case their
                    Terms and Privacy Policy will apply.]
                  </i>
                </p>
                <p>
                  When You use the “StandWeSpeak/Mae Expert/Consultancy Service”
                  provided to You on the StandWeSpeak/Mae website/bot, the
                  following Terms and condition apply:
                </p>
                <ul>
                  <li>
                    StandWeSpeak/Mae Expert/Consultancy Service is a Premium
                    Service provided by the StandWeSpeak/Mae;
                  </li>
                  <li>
                    You have chosen to utilize the Services of a
                    StandWeSpeak/Mae Expert/Consultant engaged by the
                    StandWeSpeak/Mae Website by choosing and subscribing to an
                    offered subscription, plan or bootcamp;
                  </li>
                  <li>
                    The StandWeSpeak/Mae Expert/Consultancy Service is provided
                    by highly trained and qualified professionals. By using the
                    Service You understand and agree that the Expert assigned to
                    work with You will be located remotely and may not be
                    located in Your country or state of residence.{" "}
                  </li>
                  <li>
                    The StandWeSpeak/Mae allows You to connect scheduled
                    sessions with a StandWeSpeak/Mae Expert/Consultant. You
                    understand and agree that the Services offered are solely
                    for the purpose of providing medical-legal information;
                  </li>
                  <li>
                    You understand and agree that StandWeSpeak/Mae
                    Expert/Consultancy Service does not provide a diagnosis,
                    prognosis, treatment or a cure for a
                    disease/condition/disorder or disability, and/or does not
                    provide any type of state-regulated psycho-medico-legal
                    services in Your country of residence;
                  </li>
                  <li>
                    StandWeSpeak/Mae Expert/Consultancy Service is not a crisis
                    or emergency Service. If at any time You experience an
                    emergency, medical or otherwise, please immediately reach
                    out to the emergency contact in Your local area of
                    residence;
                  </li>
                  <li>
                    The StandWeSpeak/Mae will assign a StandWeSpeak/Mae
                    Expert/Consultant to You when You subscribe to the Service
                    or when you schedule Your first session;
                  </li>
                  <li>
                    Sessions are scheduled at the mutual convenience of the User
                    and the StandWeSpeak/Mae Expert/Consultant;
                  </li>
                  <li>
                    It is the intention of Your StandWeSpeak/Mae
                    Expert/Consultant to provide Services that will assist You
                    in reaching Your goals. Based upon the Information that You
                    provide and the specifics of Your situation, Your
                    StandWeSpeak/Mae Expert/Consultant will provide
                    recommendations to You. Users, and StandWeSpeak/Mae
                    Expert/Consultant are partners in the supportive process.
                    You have the right to agree or disagree with Your
                    Expert/Consultant recommendations;
                  </li>
                  <li>
                    The StandWeSpeak/Mae serves as a “connector” for You to
                    access online StandWeSpeak/Mae Experts/Consultants. You
                    agree that You are using the Services in your best
                    judgement, and StandWeSpeak will not make any representation
                    or warranty as to (a) the accuracy or availability of the
                    StandWeSpeak/Mae Website, (b) the willingness or ability of
                    the StandWeSpeak/Mae Expert/Consultant to listen, (c) the
                    willingness or ability of any StandWeSpeak/Mae
                    Expert/Consultant to give advice, (d) whether You or anyone
                    else shall find a StandWeSpeak/Mae Expert/Consultancy
                    Service helpful, useful or satisfactory, (e) whether You or
                    anyone else shall find a StandWeSpeak/Mae Expert/Consultant
                    advice relevant, useful, accurate or satisfactory, (f)
                    whether the advice of the StandWeSpeak/Mae Expert/Consultant
                    will be relevant to the User’s question, or (g) whether the
                    StandWeSpeak/Mae Expert/Consultant advice will otherwise be
                    suitable to Your or anyone else’s needs;
                  </li>
                  <li>
                    You understand and agree that, although a StandWeSpeak/Mae
                    Expert/Consultant may have been accessed through the
                    StandWeSpeak/Mae Website, StandWeSpeak cannot predict or
                    assess the competence of, or appropriateness for Your needs,
                    of the StandWeSpeak/Mae Expert/Consultant. You also
                    acknowledge and agree that You take full responsibility for
                    the decision to access a StandWeSpeak/Mae Expert/Consultant;
                  </li>
                  <li>
                    The fee for the StandWeSpeak/Mae Expert/Consultancy Service
                    shall be as mentioned on the StandWeSpeak/Mae Website and
                    indicated to You at the time of purchase (“Fees”).
                    StandWeSpeak may at its discretion revise the Fees. Any
                    changes to the Fees will be effective only upon your
                    approval through the Website. StandWeSpeak reserves the
                    right to discontinue the StandWeSpeak/Mae Expert/Consultancy
                    Service upon non-payment of Fees by You. Fees are payable
                    through purchase through Razorpay, or other payment
                    mechanisms as StandWeSpeak may introduce in its sole
                    discretion from time to time, prior to the time the Services
                    are rendered;
                  </li>
                  <li>
                    Because StandWeSpeak/Mae Expert/Consultancy Service is not a
                    medical treatment, typically insurance will not cover the
                    cost.{" "}
                  </li>
                  <li>
                    The StandWeSpeak/Mae Website/Bot may offer integrated
                    promotions from time to time.{" "}
                  </li>
                  <li>
                    You have rights and these can be viewed in the Privacy
                    Policy. If You have any questions, concerns or complaints,
                    please contact Us at info@standwespeak.com. StandWeSpeak may
                    ask additional information from You to service Your request
                    appropriately. Please note that We may require to verify You
                    before responding to any requests to exercise Your rights;
                  </li>
                  <li>
                    StandWeSpeak reserves the right to modify or discontinue,
                    temporarily or permanently, any Service in or on the
                    StandWeSpeak/Mae Website, with or without notice to User.
                    User agrees that StandWeSpeak shall not be liable to User or
                    to any third party for any losses or damages or injury that
                    may result to User or any third party from such
                    discontinuation or interruption of Service.{" "}
                  </li>
                  <li>
                    You agree and authorize StandWeSpeak to collect and process
                    Your data in accordance with the terms of our Privacy
                    Policy;
                  </li>
                  <li>
                    StandWeSpeak/Mae Expert/Consultant MAY DISCLOSE YOUR DATA IF
                    StandWeSpeak REASONABLY BELIEVES THAT DISCLOSURE IS
                    NECESSARY IN ORDER TO COMPLY WITH A LEGAL PROCESS (SUCH AS A
                    COURT ORDER, SUBPOENA, SEARCH WARRANT, ETC.) OR OTHER LEGAL
                    REQUIREMENT OF ANY GOVERNMENTAL AUTHORITY;
                  </li>
                  <li>
                    You understand that the use of technology is not always
                    secure and You accept the technology risks during use of
                    Your mobile device , laptop or website. You will follow good
                    security practices during digital use including but not
                    limited to setting a PIN to protect access, not sharing this
                    with anyone, and not leaving your phone or chats unlocked
                    for others to see. You understand and agree that safe use is
                    Your responsibility, and StandWeSpeak will not be
                    responsible in any event arising out of unauthorized access
                    to Your chats or other data;
                  </li>
                  <li>
                    The StandWeSpeak/Mae Expert/Consultant and You mutually
                    recognize that they may hold discussions based on data and
                    information provided by You. The StandWeSpeak/Mae
                    Expert/Consultant will not voluntarily communicate Your data
                    to a third party. Any use of data disclosed by You to the
                    StandWeSpeak/Mae Expert/Consultant shall be in accordance
                    with the Privacy Policy;
                  </li>
                  <li>
                    In order to honor and protect the StandWeSpeak/Mae
                    Expert/Consultant intellectual properties, You expressly
                    agree not to disclose or communicate any proprietary
                    Information about the StandWeSpeak/Mae Expert/Consultant
                    practice, materials, or methods to any third parties. The
                    obligations under this Clause survive the termination of the
                    StandWeSpeak/Mae Expert/Consultancy Service or Your
                    relationship with StandWeSpeak/Mae;
                  </li>
                  <li>
                    The StandWeSpeak/Mae Website/Bot or StandWeSpeak/Mae
                    Expert/Consultant may provide links to external resources or
                    other content in third party websites. You warrant and agree
                    that StandWeSpeak will not be responsible or liable,
                    directly or indirectly, for any damage or loss or injury
                    caused or alleged to be caused by or in connection with the
                    use of or reliance on any such link, website, content,
                    product and/or service. StandWeSpeak encourages You, the
                    User, to, at a minimum, be aware of and to review the terms
                    of service and the privacy practices related to third party
                    sites;
                  </li>
                  <li>
                    It is agreed between You, Your heirs, agents, assigns,
                    personal representatives and next of kin and the
                    StandWeSpeak/Mae Expert/Consultant, Touchkin and their
                    officers, directors, employees, contractors, members, agents
                    and assigns that any disputes or claims arising out of or
                    relating to the Agreement, or the breach of this Agreement,
                    will be mutually addressed by both the parties towards a
                    resolution. Any such resolution may occur over the
                    telephone. In the event that the dispute has not been
                    resolved after 60 days then the terms of dispute resolution
                    mentioned in Agreement will be followed by both the parties;
                  </li>
                  <li>
                    You, for Yourself and on behalf of Your heirs, agents,
                    assigns, personal representatives and next of kin, HEREBY
                    RELEASE FROM LIABILITY, INDEMNIFY AND HOLD HARMLESS any
                    StandWeSpeak/Mae Expert/Consultant, and StandWeSpeak, and
                    their officers, directors, employees, contractors, members,
                    agents and assigns (collectively “the Indemnified Parties”)
                    from and against any and all claims, losses, causes of
                    action, costs, damages, expenses, injury and liabilities
                    whatsoever, that You, Your heirs, agents, assigns, personal
                    representatives and/or next of kin may have against the
                    Indemnified Parties, which may incur, or which may arise out
                    of or as a result of Your use of the StandWeSpeak/Mae
                    Website/Bot and/or the StandWeSpeak/Mae Expert/Consultancy
                    Service and/or reliance upon advice provided therefrom,
                    whether caused by your own actions or inactions, by the
                    negligent act(s) or omission(s) of the indemnified parties,
                    or otherwise, to the fullest extent permitted by law;
                  </li>
                  <li>
                    The StandWeSpeak/Mae Expert/Consultancy Service is provided
                    on an "as is" and "as available" basis. You understand and
                    agree that the use of the StandWeSpeak/Mae
                    Expert/Consultancy Service is based on Your own best
                    judgment and at Your own risk. To the maximum extent
                    permitted by applicable law, the StandWeSpeak/Mae
                    Expert/Consultancy Service is provided without warranties of
                    any kind, whether express or implied.
                  </li>
                  <li>
                    Video Call Service. The StandWeSpeak/Mae Expert/Consultant
                    service allows users to do an audio/video call with their
                    assigned Expert/Consultant. After Subscription, You will be
                    requested to provide an Informed Consent and Emergency
                    Contact authorization details. The Informed Consent will be
                    required before Your first Video Call Session with the
                    StandWeSpeak/Mae Expert/Consultant. Your Coach will also
                    explain the Pros and Cons of Audio/Video-based modality
                    during Your early sessions with them. The terms in this
                    Agreement will apply to this Service as well. Read the
                    Privacy Policy to understand how we handle Your data.
                  </li>
                  <li>
                    Utilizing Services provided by us is only for your personal
                    and non-commercial use and should not be shared with
                    individuals otherwise. As the nature of Services meted out
                    to you is highly sensitive in nature, and is specific to
                    your interactions with us and requirements, you shall only
                    use the advice, prescriptions, and consultations provided to
                    you, for your own purposes only. The advice so provided to
                    you, must not be shared with anyone else for their own
                    medical purposes or use.
                  </li>
                  <li>
                    You agree to use all our Services, in accordance with the
                    applicable laws, rules and regulations, or any other
                    specific restrictions on the use of the Service or content
                    therein. To find more information about our Services, or if
                    you need any assistance with our Services, please write to
                    us at info@standwespeak.com
                  </li>
                  <li>
                    Any use of the information you provide to us is subject to
                    your understanding and acceptance of our Privacy Policy.
                  </li>
                  <li>
                    In order to avail our Services, you must be of 18 years of
                    age or older or have attained the age of majority in your
                    country.
                  </li>
                  <li>
                    You further agree not to reproduce, distribute, modify,
                    display, perform, archive, publish, license, create
                    derivative works from, offer for sale, or use (except as
                    expressly authorized under the Terms) content and
                    information contained on or obtained through our service.
                    You also agree not to: circumvent, remove, degrade,
                    deactivate or thwart any of the contents of our service; use
                    any robot, spider, scraper or other means to access our
                    service. You also agree not to decompile, reverse engineer,
                    and disassemble any software or other products or processes
                    accessible through our service. You further agree not to
                    insert any code or product or manipulate the content of our
                    service in any way; or use any data mining, data gathering
                    or extraction method. Additionally, you agree not to upload,
                    post, e-mail or otherwise send or transmit any material
                    designed to interrupt, destroy or limit the functionality of
                    our service. We may terminate or restrict your use of our
                    service if you violate these Terms or engage in any illegal
                    or fraudulent use of our service.
                  </li>
                  <li>
                    You must note that certain content published on our Website
                    is purely informational in nature and is not to be treated
                    as medical advice without the express and essential
                    consultations with professionals available on our website,
                    or otherwise.
                  </li>
                  <li>
                    The Experts that you interact with have the discretion to
                    decide on the appropriate mode of communication. This will
                    be done keeping in mind the clinical protocols that the
                    Registered Medical Practitioners (RMPs) are bound by.
                  </li>
                  <li>
                    You further agree and undertake that the information you
                    provide pertaining to the context, your health condition and
                    medical condition is accurate, and agree for that to become
                    a part of your health records, diagnosis, prescription, or
                    any such other document or record created during your
                    consultation on the Website.
                  </li>
                  <li>
                    You further agree and undertake to ensure compliance with
                    the instructions of the RMPs, including the health
                    education, counseling and prescription made to you. Should
                    you deviate from such prescription, health education and
                    counseling, neither the Company nor the RMP will be liable
                    for any adverse situation arising thereof, should it be
                    determined that the adverse situation is attributable solely
                    to your deviance.
                  </li>
                  <li>
                    You are hereby undertaking that in case of a medical
                    emergency, you will not rely on the Services for treatment.
                    You must ensure that in case of a medical emergency you seek
                    urgent and immediate medical attention in a nearby physical
                    facility, or with a locally and physically available RMP.
                  </li>
                  <li>
                    Your interaction with the RMPs is governed by the applicable
                    laws and our contractual engagement with the RMPs.
                  </li>
                  <li>
                    The Services provided to you could host a range of offerings
                    which could be in the asynchronous as well as the
                    synchronous format/s accommodating real-time and store and
                    forward models for consultation. Any new modes of
                    communication, as and when introduced, will also be governed
                    by these Terms, and our other internal policies.
                  </li>
                  <li>
                    You hereby allow us to contact you through any mode or
                    medium using the contact details you provided.
                  </li>
                </ul>
                <hr />
                <h2>Registration and Processing of orders</h2>
                <p>
                  You will need to register with our Website/App/Browser to
                  access the full functionality of the Website/App/Browser and
                  also gain access to the full range of our Services, and
                  towards the same, we will seek further identifiers from your
                  end in consonance with our Privacy Policy. We engage
                  third-party service providers. In respect of the same, your
                  relevant information is shared with such third parties as
                  required.
                </p>
                <hr />
                <h2>Third Party Interactions</h2>
                <p>
                  From time to time, this Website may contain links or host
                  content or resources provided by third parties. We provide you
                  with third party links/ content solely for your information
                  and convenience. We do not make any representations or
                  warranties about the content, suitability or appropriateness
                  about the content or products contained in any third-party
                  websites or resources. We urge you to read their internal
                  policies for your continued interaction with such third
                  parties.
                </p>
                <hr />
                <h2>Disclaimer and Limitation of Liability</h2>
                <p>
                  To the maximum extent permitted by law, we will not be liable
                  in any way for any loss or damage suffered by you through use
                  of or access to this website, or our failure to provide this
                  website. Our liability for negligence, breach of contract or
                  contravention of any law as a result of our failure to provide
                  this website or any part of it, or for any problems with this
                  website, which cannot be lawfully excluded, is limited, at our
                  option and to the maximum extent permitted by law, to
                  resupplying this website or any part of it to you, or to
                  paying for the resupply of this website or any part of it to
                  you.
                </p>
                <hr />
                <h2>Suspension, Termination and Cancellation</h2>
                <p>
                  You hereby agree that we have the right to immediately suspend
                  access to the website if we suspect any violation of the Terms
                  or any applicable laws & regulations. The Company shall have
                  the right to keep and use data related to the website.
                </p>
                <hr />
                <h2>What are the Service Rules?</h2>
                <p>
                  You agree not to engage in any of the following prohibited
                  activities: (i) copying, distributing, or disclosing any part
                  of the Service in any medium, including without limitation by
                  any automated or non-automated "scraping"; (ii) using any
                  automated system, including without limitation "robots,"
                  "spiders," "offline readers," etc., to access the Service in a
                  manner that sends more request messages to the
                  StandWeSpeak/Mae servers than a human can reasonably produce
                  in the same period of time by using a conventional on-line web
                  browser; (iii) transmitting spam, chain letters, or other
                  unsolicited email; (iv) attempting to interfere with,
                  compromise the system integrity or security or decipher any
                  transmissions to or from the servers running the Service; (v)
                  taking any action that imposes, or may impose at Our sole
                  discretion an unreasonable or disproportionately large load on
                  our infrastructure; (vi) uploading invalid data, viruses,
                  worms, or other software agents through the Service; (vii)
                  disclosing, collecting or harvesting any personally
                  identifiable Information, including account names, from the
                  Service; (viii) using the Service for any commercial
                  solicitation purposes; (ix) impersonating another person or
                  otherwise misrepresenting Your affiliation with a person or
                  entity, conducting fraud, hiding or attempting to hide Your
                  identity; (x) interfering with the proper working of the
                  Service; (xi) accessing any content on the Service through any
                  technology or means other than those provided or authorized by
                  the Service; or (xii) bypassing the measures We may use to
                  prevent or restrict access to the Service, including without
                  limitation features that prevent or restrict use or copying of
                  any content or enforce limitations on use of the Service or
                  the content therein.
                  <br />
                  We may, without prior notice, change the Service; stop
                  providing the Service or features of the Service, to You or to
                  Users generally; or create usage limits for the Service. We
                  may permanently or temporarily terminate or suspend Your
                  access to the Service without notice and liability for any
                  reason, including if in Our sole determination You violate any
                  provision of the Agreement, or for no reason. Upon termination
                  for any reason or no reason, You continue to be bound by the
                  Agreement. You are responsible for all the mobile data usage
                  resulting from the use of the Service. Consult Your mobile
                  operator concerning your plan, data rate charges and limits.
                  You are solely responsible for Your interactions with other
                  StandWeSpeak/Mae Users. We reserve the right, but have no
                  obligation, to monitor disputes between You and other Users.
                  StandWeSpeak/Mae shall have no liability for Your interactions
                  with other Users, or for any User's action or inaction.
                </p>
                <hr />
                <h2>About User Content</h2>
                <p>
                  Some areas of the Service may, either now or later, allow
                  Users to post content such as chat content, profile
                  Information, videos, comments, questions, and other content or
                  Information [any such materials a User submits, posts,
                  displays, or otherwise makes available on the Service ("User
                  Content")]. We claim no ownership rights over User Content
                  created by You; the User Content You create remains Yours.
                  However, by sharing User Content through the Service, You
                  agree to allow others (in accordance with your settings and
                  this Agreement) to view, edit, and/or share Your User Content.
                  StandWeSpeak/Mae has the right (but not the obligation) in its
                  sole discretion to remove any User Content that is shared via
                  the Service.
                  <br />
                  You agree not to post or transmit User Content that: (i) may
                  create a risk of harm, loss, physical or mental injury,
                  emotional distress, death, disability, disfigurement, or
                  physical or mental illness to You, to any other person, or to
                  any animal; (ii) may create a risk of any other loss or damage
                  to any person or property; (iii) seeks to harm or exploit
                  children by exposing them to inappropriate content, asking for
                  personally identifiable details or otherwise; (iv) may
                  constitute or contribute to a crime or tort; (v) contains any
                  Information or content that we deem to be unlawful, harmful,
                  abusive, racially or ethnically offensive, defamatory,
                  infringing, invasive of personal privacy or publicity rights,
                  harassing, humiliating to other people (publicly or
                  otherwise), libelous, threatening, profane, or otherwise
                  objectionable; (vi) contains any Information or content that
                  is illegal (including, without limitation, the disclosure of
                  insider Information under securities law or of another party's
                  trade secrets); (vii) contains any Information or content that
                  You do not have a right to make available under any law or
                  under contractual or fiduciary relationships; (viii) contains
                  any Information or content that You know is not correct and
                  current; (ix) violates any school, Institution or other
                  applicable policy, including those related to cheating or
                  ethics; (x) interferes with other Users of the Service
                  including, without limitation, disrupting the normal flow of
                  dialogue in an interactive area of the Service and deleting or
                  revising any content posted by another person or entity; (xi)
                  except where expressly permitted, post or transmit charity
                  requests, petitions for signatures, franchise arrangements,
                  distributorship arrangements, sales representative agency
                  arrangements or other business opportunities (including offers
                  of employment or contracting arrangements), club memberships,
                  chain letters or letters relating to pyramid schemes, any
                  advertising or promotional materials, solicit Service letters
                  or certificates, or any other solicitation of other users to
                  use goods or services except in those areas (e.g., a
                  classified bulletin board) that are designated for such
                  purpose. You may not copy, share, or use personal identifying
                  or business contact Information about other Users without
                  their express permission. You agree that any User Content that
                  You post does not and will not violate third-party rights of
                  any kind, including without limitation any Intellectual
                  Property Rights (as defined below) or rights of privacy.
                  StandWeSpeak/Mae reserves the right, but is not obligated, to
                  reject and/or remove any User Content that StandWeSpeak/Mae
                  believes, in its sole discretion, violates these provisions.
                  <br />
                  For the purposes of this Agreement, "Intellectual Property
                  Rights" means all patent rights, copyright rights, mask work
                  rights, moral rights, rights of publicity, trademark, trade
                  dress and service mark rights, goodwill, trade secret rights
                  and other intellectual property rights as may now exist or
                  hereafter come into existence, and all applications therefore
                  and registrations, renewals and extensions thereof, under the
                  laws of any state, country, territory or other jurisdiction.
                  <br />
                  In connection with your User Content, You affirm, represent
                  and warrant the following:
                </p>
                <li>
                  You have the consent of each and every identifiable natural
                  person in the User Content to use such person's name or
                  likeness, and each such person has released You from any
                  liability that may arise in relation to such use of such User
                  Content.
                </li>
                <li>
                  Your User Content and StandWeSpeak/Mae's use thereof as
                  contemplated by this Agreement and the Service will not
                  violate any law or infringe any rights of any third party,
                  including but not limited to any Intellectual Property Rights
                  and privacy rights. StandWeSpeak/Mae takes no responsibility
                  and assumes no liability for any User Content that You or any
                  other User or third party posts or sends over the Service. You
                  shall be solely responsible for Your User Content and the
                  consequences of posting or publishing it, and You agree that
                  We are only acting as a passive conduit for Your online
                  distribution and publication of Your User Content. You
                  understand and agree that in spite of Our best efforts You may
                  be exposed to User Content that is inaccurate, objectionable,
                  inappropriate for children, or otherwise unsuited to Your
                  purpose, and You agree that StandWeSpeak/Mae shall not be
                  liable for any damages or injury or losses You allege to incur
                  as a result of User Content.
                </li>
                <hr />
                <h2>User Content License Grant</h2>
                <p>
                  By posting any User Content on the Service, for use in
                  connection with the Service You expressly grant, and You
                  represent and warrant that You have all rights necessary to
                  grant, to StandWeSpeak/Mae a royalty-free, sublicensable,
                  transferable, perpetual, irrevocable, non-exclusive, worldwide
                  license to use, reproduce, modify, publish, list Information
                  regarding, edit, translate, distribute, syndicate, publicly
                  perform, publicly display, and make derivative works of all
                  such User Content and Your name, voice, and/or likeness as
                  contained in Your User Content, in whole or in part, and in
                  any form, media or technology, whether now known or hereafter
                  developed subject to the Privacy Policy.
                </p>
                <h2>Use of User Content</h2>
                <p>
                  StandWeSpeak/Mae conducts research on Our global platform. All
                  data including User Content if any is anonymized before use
                  for research purposes. This research looks at how different
                  activities on Mobile Software (StandWeSpeak/Mae
                  Expert/Consultant conversations, other activities, etc.)
                  impact mental well-being measures. StandWeSpeak/Mae may use
                  User Content and other data collected from the User in
                  accordance with the Agreement for the purpose of this
                  research. Research and outcomes are key to helping Users make
                  real change and progress in their lives, and we are grateful
                  for the support You provide towards this mission by sharing
                  Your data.
                </p>
                <hr />
                <h2>Our Proprietary Rights</h2>
                <p>
                  Except for Your User Content, the Service and all materials
                  therein or transferred thereby, including, without limitation,
                  software, images, text, graphics, illustrations, logos,
                  patents, trademarks, service marks, copyrights, photographs,
                  audio, videos, music, and User Content belonging to other
                  Users (the "StandWeSpeak/Mae Content"), and all Intellectual
                  Property Rights related thereto, are the exclusive property of
                  StandWeSpeak/Mae and its licensors (including other Users who
                  post User Content to the Service). Except as explicitly
                  provided herein, nothing in this Agreement shall be deemed to
                  create a license in or under any such Intellectual Property
                  Rights, and You agree not to sell, license, rent, modify,
                  distribute, copy, reproduce, transmit, publicly display,
                  publicly perform, publish, adapt, edit or create derivative
                  works from any StandWeSpeak/Mae Content. Use of
                  StandWeSpeak/Mae Content for any purpose not expressly
                  permitted by this Agreement is strictly prohibited.
                  <br />
                  You may choose to or We may invite You to submit comments or
                  ideas about the Service, including without limitation about
                  how to improve the Service or Our products ("Ideas"). By
                  submitting any Idea, You agree that Your disclosure is
                  gratuitous, unsolicited and without restriction and will not
                  place StandWeSpeak/Mae under any fiduciary or other
                  obligation, and that We are free to use the Idea without any
                  additional compensation to You, and/or to disclose the Idea on
                  a non-confidential basis or otherwise to anyone. You further
                  acknowledge that, by acceptance of Your submission,
                  StandWeSpeak/Mae does not waive any rights to use similar or
                  related ideas previously known to StandWeSpeak/Mae, or
                  developed by its employees, or obtained from sources other
                  than You.
                </p>
                <h2>No Professional Advice</h2>
                <p>
                  If Mae/Chatbot provides any Information (e.g. medical or
                  legal) including recommending tools and techniques (e.g. Yoga
                  or activity or exercises), such Information is for
                  Informational purposes only and should not be construed as
                  professional advice. No action should be taken based upon any
                  Information contained in the Service. You should seek
                  independent professional advice from a person who is licensed
                  and/or qualified in the applicable area before any use.
                </p>
                <hr />
                <h2>
                  About Privacy, Security, Third-Party Links and Monitoring
                </h2>
                <p>
                  We deeply care about the privacy of Our Users. You understand
                  that by using the Services You consent to the collection, use
                  and disclosure of Your Personal data and aggregate data as set
                  forth in our Privacy Policy, and to have Your data collected,
                  used, transferred to and processed by Us in accordance with
                  Our Privacy Policy.
                  <br />
                  We deeply care about the integrity and security of Your
                  Personal data and maintain security measures as required under
                  applicable laws in India and globally to ensure its authorized
                  use. However, we cannot guarantee that unauthorized third
                  parties will never be able to defeat Our security measures or
                  use Your Personal data for improper purposes. You acknowledge
                  that You provide Your Personal data at your own risk.
                  <br />
                  The Service may contain links or other content related to
                  third-party websites, advertisers, products and/or services,
                  special offers, or other events or activities offered by third
                  parties that are not owned or controlled by StandWeSpeak/Mae.
                  If You access a third party website from the Service, You do
                  so at Your own risk, and You understand that this Agreement
                  and StandWeSpeak/Mae's Privacy Policy do not apply to Your use
                  of such sites. You expressly relieve StandWeSpeak/Mae from any
                  and all liability, directly or indirectly, arising from Your
                  use of any third-party website, service, or content and also
                  for any damage or loss caused or alleged to be caused by or in
                  connection with the use of or reliance on any such link,
                  website, content, product and/or service. Additionally, Your
                  dealings with or participation in promotions of advertisers
                  found on the Service, including payment and delivery of goods,
                  and any other terms (such as warranties) are solely between
                  You and such advertisers. You agree that StandWeSpeak/Mae
                  shall not be responsible for any loss or damage or injury of
                  any sort relating to Your dealings with such advertisers. If
                  You disclose Your Information to others, different rules may
                  apply to their use or disclosure of such Information. We do
                  not control the privacy policies of others. We encourage You
                  to ask questions before You disclose Your personal Information
                  to others. StandWeSpeak/Mae encourages You to, at a minimum,
                  be aware when You leave a third party website and to review
                  the terms of service and privacy practices related to third
                  party sites;
                  <br />
                  We have the right and liberty to monitor the content on the
                  Website at all times which shall include Information provided
                  in Your Account or any User Content posted by You. The
                  monitoring of the Website is important to determine the
                  veracity of the Information provided by You and that every
                  User remains in consonance with the Terms and Condition
                  provided herein. Subject to the Agreement, We shall also have
                  the liberty to remove any objectionable content, including
                  User Content, which is in contravention of the Agreement or
                  share such Information with any governmental authority as per
                  procedures laid down by the law for the time being in force in
                  India. It is hereby clarified that StandWeSpeak/Mae has a
                  right to but not an obligation to monitor the content on the
                  Website. Accordingly, StandWeSpeak/Mae shall not be liable for
                  any claims, losses, damages costs or expenses that may arise
                  as a consequence of the User Content published by You or any
                  other User on the Website.
                  <br />
                </p>
                <hr />
                <h2>About Payments and Cancellations</h2>
                <h3>Teleconsultations</h3>
                <p>
                  Once a session has been booked, it cannot get cancelled. Any
                  appointment or session can be rescheduled only once with the
                  same consultant or expert. No refunds will be processed in
                  case of client no-shows. In case the consultant or the expert
                  fails to show up, the client will have the choice of a
                  reschedule or a complete refund. In case there are delays from
                  the client’s end, StandWeSpeak will not be liable and will not
                  be able to provide a(n) refund/free reschedule/extended
                  appointment duration et al. Please contact us at
                  info@standwespeak.com in case you have any questions.
                </p>
                <h3>Third-party services</h3>
                <p>
                  StandWeSpeak/Mae is not responsible for any purchase or
                  payments made for the third-party services.
                </p>
                <h3>Refunds</h3>
                <p>
                  You are responsible for all the information you input in your
                  Account and all charges that result from the information.
                  StandWeSpeak shall not be responsible for any payment for an
                  incorrect Credit/Debit card account or bill payment number. In
                  case, money has been charged to your card or bank account and
                  an order was not placed due to a payment gateway or other
                  transaction error, the same amount will be refunded within 72
                  working hours of your completion of the transaction. You may
                  inform us regarding the same by reaching out to our customer
                  support team at info@standwespeak.com. All refunds will be
                  credited to the original payment method. However, StandWeSpeak
                  disclaims any and all liability, including for refunds, in the
                  event of any error arising from a failure in the applicable
                  bank or credit/debit card service provider’s system or
                  network. In case a payment has been wrongfully made and
                  credited to your Credit/Debit card account or bank account,
                  StandWeSpeak reserves the right to automatically initiate a
                  refund through your bank. By agreeing to these terms, you
                  hereby consent to such a refund initiated by your concerned
                  Bank, at the request of StandWeSpeak, to offset any incorrect
                  transaction, credit or double payment made to your account.
                  Once such a transaction is processed, StandWeSpeak shall
                  inform you of the same and the corrective action taken by
                  StandWeSpeak to refund such payment.
                </p>
                <hr />
                <h2>Indemnity and Warranty</h2>
                <h3>Indemnity</h3>
                <p>
                  You agree to defend, indemnify and hold harmless
                  StandWeSpeak/Mae and its subsidiaries, agents, licensors,
                  managers, and other affiliated companies, and their employees,
                  contractors, agents, officers and directors, from and against
                  any and all claims, damages, injury, obligations, losses,
                  liabilities, costs or debt, and expenses (including but not
                  limited to attorney's fees) arising from: (i) Your use of and
                  access to the Service, including any data or content
                  transmitted or received by You; (ii) Your violation of any
                  term of this Agreement, including without limitation Your
                  breach of any of the representations and warranties above;
                  (iii) Your violation of any third-party right, including
                  without limitation any right of privacy or Intellectual
                  Property Rights; (iv) Your violation of any applicable law,
                  rule or regulation; (v) any claim or damages that arise as a
                  result of any of Your User Content or any that is submitted
                  via Your account; or (vi) any other party's access and use of
                  the Service with Your unique username, PIN, password or other
                  appropriate security code.
                </p>
                <h3>No Warranty</h3>
                <p>
                  THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
                  BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM
                  EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED
                  WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                  INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                  NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                  WRITTEN, OBTAINED BY YOU FROM StandWeSpeak/Mae OR THROUGH THE
                  SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.
                  WITHOUT LIMITING THE FOREGOING, StandWeSpeak/Mae, ITS
                  SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT
                  THAT THE CONTENT IS ACCURATE, RELIABLE OR CORRECT; THAT THE
                  SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE WILL BE
                  AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR
                  SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT
                  THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                  ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
                  OF THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE
                  SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
                  MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD
                  OR YOUR USE OF THE SERVICE.
                  <br />
                  StandWeSpeak/Mae DOES NOT WARRANT, ENDORSE, GUARANTEE, OR
                  ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                  OFFERED BY A THIRD PARTY THROUGH THE StandWeSpeak/Mae SERVICE
                  OR ANY HYPERLINKED WEBSITE OR SERVICE, AND StandWeSpeak/Mae
                  WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION
                  BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                </p>
                <h3>Limitation of Liability</h3>
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  SHALL StandWeSpeak/Mae, ITS AFFILIATES, AGENTS, DIRECTORS,
                  EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY DIRECT,
                  INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                  EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR
                  LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
                  LOSSES, THAT RESULT FROM THE USE OF, OR INABILITY TO USE, THIS
                  SERVICE. UNDER NO CIRCUMSTANCES WILL StandWeSpeak/Mae BE
                  RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM
                  HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE
                  SERVICE OR YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN.
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
                  StandWeSpeak/Mae ASSUMES NO LIABILITY OR RESPONSIBILITY FOR
                  ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II)
                  PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
                  RESULTING FROM YOUR ACCESS TO OR USE OF OUR SERVICE; (III) ANY
                  UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
                  AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY
                  INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                  SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
                  THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD
                  PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY
                  LOSS OR DAMAGE OR INJURY INCURRED AS A RESULT OF THE USE OF
                  ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
                  AVAILABLE THROUGH THE SERVICE; AND/OR (VII) USER CONTENT OR
                  THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD
                  PARTY. IN NO EVENT SHALL StandWeSpeak/Mae, ITS AFFILIATES,
                  AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE
                  LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES,
                  OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING
                  THE AMOUNT YOU PAID TO THE FREE StandWeSpeak/Mae HEREUNDER OR
                  $100.00, WHICHEVER IS GREATER. FOR THE PAID SUBSCRIPTION TO
                  StandWeSpeak/Mae Expert/Consultancy SERVICE, THE AGGREGATE
                  LIABILITY OF TOUCHKIN SHALL BE LIMITED TO THE SUBSCRIPTION FEE
                  PAID BY YOU FOR A PERIOD OF SIX (6) MONTHS FOR THE
                  StandWeSpeak/Mae Expert/Consultancy SERVICE .<br />
                  THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE
                  ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE,
                  STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF StandWeSpeak/Mae
                  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE
                  FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST
                  EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
                  <br />
                  The Service is controlled and operated from facilities in
                  India. You understand and agree that, while Services meet all
                  applicable laws in India, the Service may not meet licensing
                  or other regulatory requirements in other locations. You agree
                  that those who access or use the Service from other
                  jurisdictions will do so at their own volition and are
                  entirely responsible for compliance with all applicable Indian
                  and local laws and regulations, including but not limited to
                  export and import regulations. You may not use the Service if
                  You are a resident of a country embargoed, or are a foreign
                  person or entity blocked or denied, as identified by embargo
                  and sanctions lists maintained by the United Nations, USA, UK
                  or India. Unless otherwise explicitly stated, all materials
                  found on the Service are solely directed to individuals,
                  companies, or other entities located in India.
                </p>
                <hr />
                <h2>Governing Law and Dispute Resolution</h2>
                <p>
                  You agree that: (i) the Service shall be deemed solely based
                  in Raipur, Chhattisgarh, India; and (ii) the Service shall be
                  deemed a passive one that does not give rise to personal
                  jurisdiction over StandWeSpeak/Mae, either specific or
                  general, in jurisdictions other than Raipur, Chhattisgarh,
                  India. This Agreement shall be governed by the internal
                  substantive laws of India, without respect to its conflict of
                  laws principles. You agree to submit to the personal
                  jurisdiction of the courts located in Raipur, Chhattisgarh,
                  India for any actions for which we retain the right to seek
                  injunctive or other equitable relief in a court of competent
                  jurisdiction to prevent the actual or threatened infringement,
                  misappropriation or violation of Our copyrights, trademarks,
                  trade secrets, patents, or other intellectual property or
                  proprietary rights.
                </p>
                <h2>Events beyond our reasonable control</h2>
                <p>
                  We will not be held responsible for any delay or failure to
                  comply with Our obligations under these Agreement if the delay
                  or failure arises from any cause which is beyond Our
                  reasonable control. This condition does not affect your
                  statutory rights.
                </p>
                <h2>Assignment</h2>
                <p>
                  This Agreement, and any rights and licenses granted hereunder,
                  may not be transferred or assigned by You, but may be assigned
                  by StandWeSpeak/Mae without restriction. Any attempted
                  transfer or assignment in violation hereof shall be null and
                  void.
                </p>
                <h2>Notification Procedures and Changes to the Agreement</h2>
                <p>
                  StandWeSpeak/Mae may provide notifications, whether such
                  notifications are required by law or are for marketing or
                  other business related purposes, to You via email notice,
                  written or hard copy notice, or through posting of such notice
                  on Our website, as determined by StandWeSpeak/Mae in Our sole
                  discretion. StandWeSpeak/Mae reserves the right to determine
                  the form and means of providing notifications to Our Users,
                  provided that You may opt out of certain means of notification
                  as described in this Agreement. StandWeSpeak/Mae is not
                  responsible for any automatic filtering You or Your network
                  provider may apply to email notifications we send to the email
                  address you provide us. StandWeSpeak/Mae may, in its sole
                  discretion, modify or update this Agreement from time to time,
                  and so You should review this page periodically. When We
                  change the Agreement in a material manner, We will update the
                  'last modified' date at the top of this page. Your continued
                  use of the Service after any such change constitutes Your
                  acceptance of the new Terms and Condition. If You do not agree
                  to any of these terms or any future Terms and Condition, do
                  not use or access (or continue to access) the Service.
                </p>
                <h2>Entire Agreement/Severability.</h2>
                <p>
                  This Agreement and Terms and Condition, together with any
                  amendments, policies and any additional agreements You may
                  enter into with StandWeSpeak/Mae in connection with the
                  Service, shall constitute the entire agreement between You and
                  StandWeSpeak/Mae concerning the Service. If any provision of
                  this Agreement is deemed invalid by a court of competent
                  jurisdiction, the invalidity of such provision shall not
                  affect the validity of the remaining provisions of this
                  Agreement, which shall remain in full force and effect.
                </p>
                <h2>No Waiver.</h2>
                <p>
                  No waiver of any term of this Agreement shall be deemed a
                  further or continuing waiver of such term or any other term,
                  and StandWeSpeak/Mae's failure to assert any right or
                  provision under this Agreement shall not constitute a waiver
                  of such right or provision.
                </p>
                <h2>Severability</h2>
                <p>
                  If any provision or provisions of these Terms, shall be held
                  to be illegal, invalid, or, unenforceable, the validity,
                  legality, and enforceability of the remaining provisions shall
                  remain in full force and effect.
                </p>
                <h2>
                  Who can You contact for additional questions, comments or
                  concerns?
                </h2>
                <p>
                  If You have any questions, comments, or concerns about this
                  Agreement or our other privacy policies or practices, please
                  contact us at info@standwespeak.com or by writing to:
                </p>
                <p>
                  304, Amaltas, D.M. Vatika,
                  <br />
                  Shankar Nagar, Raipur,
                  <br />
                  Chhattisgarh, INDIA-492001
                  <br />
                </p>
                <hr />
                <br />
                <h2>Changes to Terms and Conditions and Assignment</h2>
                <p>
                  We may from time to time change these Terms and Conditions. We
                  will notify you at least 30 days before such changes apply to
                  you. We may assign or transfer our agreement with you
                  including our associated rights and obligations at any time
                  and you agree to cooperate with us in connection with such an
                  assignment or transfer. We recommend that you periodically
                  check this page for any revised terms. Your continued use of
                  the Services will be deemed to constitute your acceptance of
                  all such revised terms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default TermsConditions;
