import { ADD_NEW_MESSAGE, REMOVE_LOADING_MESSAGE } from '../constant'

export const addMessage = (messageObj) => ({
  type: ADD_NEW_MESSAGE,
  payload: messageObj
})

export const removeMessage = () => ({
  type: REMOVE_LOADING_MESSAGE
})
