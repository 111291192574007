import React, { Component } from "react";
import { Button, Col, Nav, Row, Tab, Table } from "react-bootstrap";
import {
  changeDateFormat,
  changeTimeFormat,
  redirectToSite,
} from "../../Utils/util";
import { FaExternalLinkAlt } from "react-icons/fa";
import authAxios from "../../Components/Reusable/AuthAxios";
import Logo from "../../Assets/Images/Company_Logo.png";
import "./style.css";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { MdRefresh } from "react-icons/md";

class AdminApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      payments: [],
      modalShow: false,
    };
  }

  getAppointments = async (user_id) => {
    let data = {
      user_id: user_id,
    };
    const res = await authAxios.post(
      `${process.env.REACT_APP_BACKEND_URL}/appointments/find`,
      data
    );
    // console.log(res);
    if (res && res.status === 403) {
      // console.log(res.data);
      return [];
    }
    return res?.data;
  };

  getPayments = async (user_id) => {
    let data = {
      user_id: user_id,
    };
    const res = await authAxios.post(
      `${process.env.REACT_APP_BACKEND_URL}/payments/find`,
      data
    );
    // console.log(res);
    if (res && res.status === 403) {
      // console.log(res.data);
      return [];
    }
    return res?.data;
  };

  addFailedPayments = async (apt, response) => {
    let data = {
      user_id: apt.user_id,
      receipt: "Receipt No-" + response.appt_id,
      paid_method: "UPI/Others",
      amt_paid: parseInt(apt.amount),
      status: response.status ? response.status : "Failed",
      payment_id: response.error.metadata.payment_id,
      order_id: response.error.metadata.order_id,
      razorpay_signature: "",
      razorpay_error: "",
      created_at: new Date(),
    };
    //JSON.stringify(response.error)
    const res = await authAxios.post(
      `${process.env.REACT_APP_BACKEND_URL}/payments/insert`,
      data
    );
    if (res && res.status === 403) {
      console.log(res.data);
    } else {
      console.log(res?.data);
      this.refresh();
    }
    // console.log(res);
    // console.log(res?.data);
  };

  addPayments = async (apt, response) => {
    let data = {
      user_id: apt.user_id,
      receipt: "Receipt No-" + response.appt_id,
      paid_method: "UPI/Others",
      amt_paid: parseInt(apt.amount),
      status: response.status,
      payment_id: response.razorpay_payment_id,
      order_id: response.razorpay_order_id,
      razorpay_error: "",
      razorpay_signature: response.razorpay_signature,
    };
    const res = await authAxios.post(
      `${process.env.REACT_APP_BACKEND_URL}/payments/insert`,
      data
    );
    if (res && res.status === 403) {
      console.log(res?.data);
    } else {
      console.log(res?.data);
      this.refresh();
    }
    const s = await authAxios.post(
      `${process.env.REACT_APP_BACKEND_URL}/appointments/update?id=${response.appt_id}`,
      { status: 3 }
    );
    console.log(s?.data);
  };

  async componentDidMount() {
    let user_id = JSON.parse(localStorage.getItem("user"))._id;
    let appointments = await this.getAppointments(user_id);
    let payments = await this.getPayments(user_id);
    // console.log(appointments);
    this.setState({ appointments: appointments });
    this.setState({ payments: payments });
  }

  payAmount(apt) {
    if (!apt.order_id) {
      alert("No Order Id given");
    }
    var App_this = this;
    // key: process.env.REACT_APP_RZP_KEY_ID,
    const options = {
      key: "rzp_test_rxjZt2SREJ618a",
      currency: "INR", //data.currency,
      amount: apt.amount, //data.amount.toString(),
      order_id: apt.order_id,
      image: Logo,
      name: apt.consultant_name,
      description: "Consultant " + apt.consultant_name + "'s fees",
      handler: function (response) {
        // console.log(response);
        // console.log(response.razorpay_payment_id);
        // console.log(response.razorpay_order_id);
        // console.log(response.razorpay_signature);
        // verify the authencity of payment
        // generated_signature = hmac_sha256(order_id + "|" + razorpay_payment_id, secret);
        // if (generated_signature === razorpay_signature) {
        //     payment is successful
        // }
        response.status = "Success";
        response.appt_id = apt._id;
        App_this.addPayments(apt, response);
        alert("Transaction successful");
      },
      // prefill: {
      //     name: "",
      //     email: "stevy@mail.com",
      //     phone_number: "9899192901",
      // },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.on("payment.failed", function (response) {
      response.appt_id = apt._id;
      App_this.addFailedPayments(apt, response);
      console.log(response.error);
      // console.log(response.error.description);
      // console.log(response.error.source);
      // console.log(response.error.step);
      // console.log(response.error.reason);
      // console.log(response.error.metadata.order_id);
      // console.log(response.error.metadata.payment_id);
    });
    rzp.open();
  }

  refresh = async () => {
    let user_id = JSON.parse(localStorage.getItem("user"))._id;
    let appointments = await this.getAppointments(user_id);
    let payments = await this.getPayments(user_id);
    // console.log(appointments);
    this.setState({ appointments: appointments });
    this.setState({ payments: payments });
  };
  render() {
    // console.log(this.props?.login?.isUserLogged);
    if (!this.props?.login?.isUserLogged) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        <div className="container">
          {this.state.modalShow ? (
            <div style={{ display: "none" }}></div>
          ) : null}
          <Tab.Container id="left-tabs-example" defaultActiveKey="Booking">
            <Row>
              <Col sm={12} className="admin-navs">
                <Nav variant="pills" className="flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey="Booking">Booking</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Payments">Payments</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Schedule">Schedule</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Button
                  style={{ textAlign: "right", marginBottom: 15 }}
                  onClick={() => this.refresh()}
                >
                  Refresh <MdRefresh />{" "}
                </Button>

                <Tab.Content>
                  <Tab.Pane eventKey="Booking">
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Sr. no.</th>
                          <th>Appointment no.</th>
                          <th>Consultant Name</th>
                          <th>Status</th>
                          <th>Meeting status</th>
                          <th>Created on</th>
                          <th>Reason/Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.appointments?.length > 0
                          ? this.state.appointments.map((v, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{v._id.slice(0, 6) + ".."}</td>
                                  <td>{v.consultant_name}</td>
                                  <td>{v.status}</td>
                                  <td>
                                    {v.status === 2 ? (
                                      <Button
                                        onClick={() => {
                                          this.payAmount(v);
                                        }}
                                        style={{ padding: "0 40px" }}
                                      >
                                        Pay Here
                                      </Button>
                                    ) : v.status === 3 ? (
                                      "Paid"
                                    ) : (
                                      "Scheduling"
                                    )}
                                  </td>
                                  <td>{changeDateFormat(v.createdAt)}</td>
                                  <td>{v.closing_reason}</td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Payments">
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Payment ID</th>
                          <th>Reciept Number</th>
                          <th>Paid By</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.payments.length > 0
                          ? this.state.payments.map((v, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{v._id}</td>
                                  <td>{v.receipt}</td>
                                  <td>{v.paid_method}</td>
                                  <td>{v.amt_paid}</td>
                                  <td>{v.status}</td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Schedule">
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Appointment No.</th>
                          <th>Consultant</th>
                          <th>Timing</th>
                          <th>Platform</th>
                          <th>Link</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.appointments.length > 0
                          ? this.state.appointments
                              .filter((v) => v.status === 3)
                              .map((v, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{v._id}</td>
                                    <td>{v.consultant_name}</td>
                                    <td>
                                      {(v.meeting_timing
                                        ? changeDateFormat(v.meeting_timing)
                                        : "") +
                                        " " +
                                        (v.meeting_timing
                                          ? changeTimeFormat(v.meeting_timing)
                                          : "")}
                                    </td>
                                    <td>{"Google Meets"}</td>
                                    <td
                                      className="cursorOnHover"
                                      onClick={() => {
                                        redirectToSite(v.meeting_link);
                                      }}
                                    >
                                      {"Start Meeting"}{" "}
                                      <FaExternalLinkAlt color={"green"} />
                                    </td>
                                  </tr>
                                );
                              })
                          : null}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.LoginReducer,
  };
}

export default connect(mapStateToProps, null)(AdminApplication);
